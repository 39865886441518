/*
* @Author: hcx
* @Date:   2019-07-13 01:36:15
* @Last Modified by:   hcx
* @Last Modified time: 2019-07-13 12:59:22
*/
export const publicMixin = {
    data () {
        return {
            mixinObj: {
                optionSection: [ 0, 9 ],
                threeType: 3,
                threeTypeZu3: '3-3',
                threeTypeZu6: '3-6',
                fiveType: 5,
                sevenType: 7,
                initThree: {
                    option: [
                        { title: '百位', activeActive: '', props: 'bai' },
                        { title: '十位', activeActive: '', props: 'shi' },
                        { title: '个位', activeActive: '', props: 'ge' },
                    ],
                    // isMoreSection: false,
                    // 3 -> 排列三 3-3 -> 排列三组3 3-6 -> 排列三组6 5 -> 排列五 7 -> 排列7
                    type: '3',
                },
                initThreeZu3: {
                    option: [
                        { title: '', activeActive: '', props: 'nums' },
                    ],
                    type: '3-3',
                },
                initThreeZu6: {
                    option: [
                        { title: '', activeActive: '', props: 'nums' },
                    ],
                    type: '3-6',
                },
                initFive: {
                    option: [
                        { title: '万位', activeActive: '', props: 'wan' },
                        { title: '千位', activeActive: '', props: 'qian' },
                        { title: '百位', activeActive: '', props: 'bai' },
                        { title: '十位', activeActive: '', props: 'shi' },
                        { title: '个位', activeActive: '', props: 'ge' },
                    ],
                    type: '5',
                },
                initSeven: {
                    option: [
                        { title: '第一位', activeActive: '', props: 'one' },
                        { title: '第二位', activeActive: '', props: 'two' },
                        { title: '第三位', activeActive: '', props: 'three' },
                        { title: '第四位', activeActive: '', props: 'four' },
                        { title: '第五位', activeActive: '', props: 'five' },
                        { title: '第六位', activeActive: '', props: 'six' },
                        { title: '第七位', activeActive: '', props: 'seven' },
                    ],
                    type: '7',
                },
            },
        };
    },
    methods: {

        /**
         * [subDataProcessing               获取到的数据处理]
         * @param  {[type]} arr             [数据]
         * @param  {[type]} type            [类型]
         * @return {[type]}                 [description]
         */
        getDataProcessing ( arr, type ) {
            let newArr = [];
            let initOption = type == this.mixinObj.threeType
                ? this.mixinObj.initThree.option
                : type == this.mixinObj.fiveType
                    ? this.mixinObj.initFive.option
                    : type == this.mixinObj.sevenType
                        ? this.mixinObj.initSeven.option
                        : type == this.mixinObj.threeTypeZu3
                            ? this.mixinObj.initThreeZu3.option
                            : type == this.mixinObj.threeTypeZu6
                                ? this.mixinObj.initThreeZu6.option
                                : [];
            arr.map( ( firstItem ) => {
                let firstArr = [];
                initOption.map( ( secondItem, secondIndex ) => firstArr.push( firstItem.detail[ secondItem.props ] ) );
                newArr.push( firstArr );
            } );
            return newArr;
        },
    },
};
