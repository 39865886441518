<template>
    <div class="content rank-details">
        <div class="row-item result-content" v-if="resultFlag">
            <div class="row-item-left">
                <input v-for="( item, index ) in data.result" class="item-btn selection" type="button" :value="item" />
            </div>
        </div>
        <div class="row-item"
            v-for="( item,index ) in data.selectContent"
            :key="`row-${index}`"
            :class="{ 'no-first-row': index != 0 }"
            v-else>
            <div class="row-item-left">
                <template v-for="( item2, index2 ) in item">
                    <template v-for="( item3, index3 ) in item2">
                        <input class="item-btn"
                            :class="{
                                'selection': data.order_info.prize_cash == 0
                                    ? true
                                    : (['3-3', '3-6'].includes( rankZuType )
                                        ? data.result.includes( item3 )
                                        : item2.includes( data.result[ index2 ] ) && item3 === data.result[ index2 ]),
                            }"
                            type="button"
                            :value="item3" />
                        <div class="solid" v-if="index3 === item2.length - 1 && index2 != item.length - 1"></div>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script scoped>
    export default {
        props: [ 'row', 'isLottery', 'data', 'resultFlag', 'rankZuType' ],
    };
</script>

<style lang="less" scoped>
    @import '~@/assets/css/common.less';
    @margin10px: 10px;
    .content {
        .m( 0 -17px );
        .row-item {
            .d-f-c( row, flex-start, center );border-bottom:1px solid rgba(60, 69, 78, 0.8);
            &-left {
                .d-f-c( row, flex-start, center ); .p( 0 16px );
                .solid {
                    .w( 2px ); .h( 22px ); .bg( #BE0B00 ); .bor( 1px ); opacity:1; .m( -@margin10px 4px 0 4px );
                }
                .item-btn {
                    .mb( @margin10px );.w( 25px );.h( 25px );.mr( 2px ); .bg( #fff );
                }
            }
            &.no-first-row {
                .bd-t( 1px, solid, #e8eaec ); .pt( @margin10px );
            }
            &.result-content {
                justify-content: center;
                .item-btn {
                    .mr( 8px );
                }
            }
        }
        .row-item:last-child{
            border-bottom:0;
        }
        .item-btn {
            .bor( 50% ); .h( 36px ); .w( 36px ); .bd( 1px, solid, #FFCDCD ); .fz( 14px ); .fw( 600 );
            .c( #BE0B00 ); .mr( 10px );
            &.first-row{
                .mb( 10px );
            }
            &.selection{
                .bg( #BE0B00 ); .c; border-color: #BE0B00;
            }
        }
    }
    .spzhi{
      .content .row-item-left .item-btn{
          margin-bottom: 0;
      }
  }
</style>