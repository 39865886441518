<template>
    <div class="planDetails" v-if="data">
        <!-- 方案详情 -->
        <template v-if="$route.name == '方案详情'&& $route.query.lottery_type != 10 ">
            <div class="plan-head">
                <!-- <div class="text-center">
                    <el-button-group>
                        <el-button v-for="( item, index ) in init.list" :key="`tab-${ index }`" @click="activeName = item.name" :type="activeName === item.name? 'primary': ''">{{ item.name }}</el-button>
                    </el-button-group>
                </div> -->
                <mySteps :data="data" />
            </div>
    
            <div class="plan-table">
                <div class="table-head">
                    <div class="table-head-left col-b">
                        <span class="span1">{{ data.order_info.lottery_name }}</span>
                        <span class="line"></span>
                        <template v-if="init.detailHead.includes( data.order_info.lottery_type )">
                            <template v-if="data.order_info.lottery_type == 2 || data.order_info.lottery_type == 4">
                                <span class="span2">第</span>
                                <span class="span2">{{ data.order_info.qishu }}</span>
                                <span class="span4">期</span>
                                <span class="line"></span>
                            </template>
                            <template v-if="data.order_info.filter_plan == 1">
                                <span class="span2">选</span>
                                <span class="span3">{{ data.plan_detail.length }}</span>
                                <span class="span4">场</span>
                                <span class="line"></span>
                            </template>
                            <span class="span5">{{ data.order_info.pass_type.replace(/\,/g, '串1，') }}串1</span>
                        </template>
                        <template v-if="init.qishuHead.includes( data.order_info.lottery_type )">
                            <span class="span2" v-if="data.order_info.lottery_type == 6">{{ data.order_info.additional == 0? '普通投注': '追加投注' }}</span>
                            <span class="span2">{{ data.order_info.qishu }}</span>
                            <span class="span4">期</span>
                        </template>
                        <template v-if="init.rankHead.includes( data.order_info.lottery_type )">
                            <span class="span2">{{data.order_info.play_type == 1? '直选': (data.order_info.play_type==2?'组选三':'组选六')}}</span>
                            <span class="line"></span>
                            <span class="span2">第</span>
                            <span class="span2">{{ data.order_info.qishu }}</span>
                            <span class="span4">期</span>
                        </template>
                    </div>
                    <div class="table-head-right col-b" v-if="!init.rankHead.includes( data.order_info.lottery_type )">
                        <span class="span1">方案截止时间：</span>
                        <span class="span2 theme-color">{{ data.order_info.print_end_time }}</span>
                    </div>
                </div>
                <!-- <Divider /> -->
                <myTableJc :data="data" v-if="data.order_info.lottery_type == 1"/>
                <myTableBjdc :data="data" v-if="data.order_info.lottery_type == 2" />
                <myTableZc :data="data" v-if="data.order_info.lottery_type == 3" />
                <myTableSfgg :data="data" v-if="data.order_info.lottery_type == 4" />
                <myTableLq :data="data" v-if="data.order_info.lottery_type == 5"/>
                <myTableLotto :data="data" v-if="data.order_info.lottery_type == 6 || data.order_info.lottery_type == 12"/>
                <myTableRank :data="data" v-if="init.rankHead.includes( data.order_info.lottery_type )"/>
            </div>
        </template>
        <template v-if="$route.name == '方案详情'&& $route.query.lottery_type == 10 ">
            <select-five :data="data"></select-five>
        </template>
        <!-- 出票详情 -->
        <template v-if="$route.name == 'ticketDetail'">
          <ticketDetail/>
        </template>
        <template>
           <jointDetail></jointDetail>
        </template>
        <div class="mt20">
            <div class="fs14 fw mb10">出票票样</div>
            <div class="flex" v-if="tk_img">
                <div class="mr10" v-for="item in tk_img"><img @click="imgurl = item" class="img1" :src="item" /></div>
            </div>
            <div v-else class="tc col-9 p10">暂无票样</div>
        </div>
        <showimg v-if="imgurl" :imgurl="imgurl" @hideImg="imgurl = ''"></showimg>
    </div>
</template>
<script>
	import showimg from '@/components/showImg'
    import {planDetail} from '../../../api/api';
    import selectFive from './selectFive';
    import jointDetail from './joint_detail/index';
    import { planMixin } from './planMixin';
    import mySteps from './step';
    import ticketDetail from './ticketDetail';
    import myTableJc from './table/jczq/jcTable';
    import myTableLq from './table/jclq/jcTable';
    import myTableLotto from './table/lotto/table';
    import myTableRank from './table/rank/table';
    import myTableBjdc from './table/bjdc';
    import myTableSfgg from './table/sfgg'
    import myTableZc from './table/zc';
    import { Divider } from 'iview';
    export default {
        mixins: [ planMixin ],
        data () {
            return {
                activeName: '选号详情',
                init: {
                    list: [
                        { name: '选号详情' },
                        { name: '出票详情' },
                    ],
                    detailHead: [ 1, 2, 4, 5],
                    rankHead: [ 7, 8, 9, 11 ],
                    qishuHead: [ 3, 6, 12 ],
                },
                data: null,
                secondNav: [
                    {
                        path: 'planDetail',
                        meta: { title: '选号详情', index: 2, type: 1 },
                        name: 'planDetail',
                    },
                    // {
                    //     path: 'ticketDetail',
                    //     meta: { title: '出票详情', index: 2, type: 1 },
                    //     name: 'ticketDetail',
                    // },
                ],
                tk_img: [],
				imgurl: ''
            };
        },
        created () {
            console.log(this.$route.name,11)
            this.getTicketDetail();
        },
        methods: {
            handleClick ( tab, event ) {},
            async getTicketDetail () {
                // let params = {
                //     order_id: this.$route.query.order_id,
                //     user_id: this.$route.query.user_id,
                //     lottery_type: this.$route.query.lottery_type,
                //     from_shop: '1',
                // };
                // const res = await this.HTTP.get( this.API.TicketDetail, params );
                // this.data = res.data.data;
                let params = {
                    order_id: this.$route.query.order_id,
                    user_id: this.$route.query.user_id,
                    lottery_type: this.$route.query.lottery_type,
                    store_id: this.$route.query.store_id,
                    from_shop: '1',
                };
                const res = await planDetail(params)
                this.data = res.data;
                if(this.data.order_info.tk_img) this.tk_img = JSON.parse(this.data.order_info.tk_img)

            },
        },
        components: {
            mySteps, myTableJc, Divider, myTableLq, myTableLotto, myTableZc, myTableBjdc, myTableSfgg, ticketDetail, myTableRank, selectFive, jointDetail, showimg
        },
    };
</script>
<style lang="less" scoped>
	.planDetails {
		width: 1000px;
		margin: 0 auto;
		margin-bottom: 100px;
		.plan-head {
			background: rgb(248, 248, 248);
		}
		.plan-table {
			font-size: 14px;
			margin-bottom: 0;
			background-color: #39444e;
			color: #fff;
			.table-head {
				line-height: 40px;
				display: flex;
				justify-content: space-between;
				margin: 0 10px;
				.table-head-left {
					.span2,
					.span5 {
						margin-left: 0px;
					}
				}
			}
			.theme-color {
				color: #00a79d;
			}
		}
		.line {
			display: inline-block;
			height: 12px;
			background-color: #fff;
			width: 1px;
			margin: 0 10px;
		}
    }
    .img1{ width: 140px; height: auto; border-radius: 2px; }
</style>