<template>
    <div class="table-page">
        <!-- filter_plan 1：非导入方案；2： 导入方案 -->
        <div class="filter_plan" v-if="filter_plan == 2">
            <img :src="importImg"/>
            <p>导入方案无法查看选单详情</p>
        </div>
        <template v-if="filter_plan == 1">
            <ivuTable border :row-class-name="noIsLottery" :columns="table.columns" :data="data.list" />
        </template>
    </div>
</template>

<script>
    import { Table as ivuTable } from 'iview';
    import tableTag from './tag';
    import importImg from '@/assets/img/order/import.png';
    export default {
        props: {
            data: {
                required: true,
            },
        },
        components: {
            ivuTable,
            tableTag,
        },
        data () {
            return {
                filter_plan: this.data.order_info.filter_plan,
                importImg,
                table: {
                    columns: [
                        {
                            title: '序号',
                            width: 100,
                            align: 'center',
                            // key: 'match_turn',
                            className: 'touzhuxiang',
                            render: ( h, params ) => h( 'div', [
                                h( 'span', {
                                    style: {
    
                                        height: '26px', fontSize: '12px', textAlign: 'center', lineHeight: '26px', borderRadius: '50%', marginRight: '8px',
                                    },
                                }, params.row.match_turn ),
                            ] ),
                        },
                        {
                            title: '时间',
                            minWidth: 120,
                            align: 'center',
                            className: 'touzhuxiang',
                            render: ( h, params ) => h( 'div', params.row.start_time ),
                        },
                        {
                            title: '对阵',
                            minWidth: 249,
                            align: 'center',
                            className: 'touzhuxiang',
                            render: ( h, params ) => {
                                let str = '';
                                if ( params.row.status >= 100 ) {
                                    str = '延期';
                                } else {
                                    str = 'VS';
                                }
    
                                return h( 'div', [
                                    h( 'span', {
                                        style: {
                                            width: '75px', display: 'inline-block', textAlign: 'right',
                                        },
                                    }, params.row.zhudui_name ),
                                    h( 'span', {
                                        style: {
                                            color: '#A0A0B4',
                                            margin: '0 3px', width: '35px', display: 'inline-block', textalign: 'center',
                                        },
                                    }, str ),
                                    h( 'span', {
                                        style: {
                                            width: '75px', display: 'inline-block', textAlign: 'left',
                                        },
                                    }, params.row.kedui_name ),
                                ] );
                            },
                        },
                        {
                            title: '投注项',
                            align: 'center',
                            minWidth: 150,
                            className: 'touzhuxiang',
                            render: ( h, params ) => h( tableTag, {
                                props: {
                                    row: params.row,
                                    isLottery: this.data.order_info.prize_cash != 0,
                                    type: this.data.order_info.play_type,
                                    result: params.row.result,
                                },
                            } ),
                        },
                        {
                            title: '胆',
                            align: 'center',
                            minWidth: 50,
                            className: 'touzhuxiang',
                            render: ( h, params ) => {
                                if ( params.row.fixed ) {
                                    return h( 'div', [
                                        h( 'span', {
                                            style: {
                                                width: '26px', height: '26px', fontSize: '12px', color: '#fff', background: '#be0b00', display: 'inline-block', textAlign: 'center', lineHeight: '26px', borderRadius: '50%',
                                            },
                                        }, '胆' ),
                                    ] );
                                }
                            },
                        },
                        {
                            title: '比分',
                            align: 'center',
                            minWidth: 60,
                            className: 'touzhuxiang',
                            render: ( h, params ) => {
                                if ( params.row.status != 50 ) {
                                    return h( 'div', '' );
                                } else if ( params.row.status == 50 ) {
                                    return h( 'div', {
                                        style: {
                                            color: '#BE0B00',
                                            fontWeight: '600',
                                        },
                                    }, [
                                        h( 'span', params.row.zhujin ),
                                        h( 'span', ' - ' ),
                                        h( 'span', params.row.kejin ),
                                    ] );
                                }
                            },
                        },
                        {
                            title: '彩果',
                            align: 'center',
                            minWidth: 60,
                            className: 'touzhuxiang',
                            render: ( h, params ) => {
                                if ( params.row.status >= 100 ) {
                                    return h( 'div', {style: {
                                        color: '#BE0B00',
                                    },
                                    }, '延期' );
                                } else if ( params.row.status > 50 ) {
                                    return h( 'div', params.row.status_str );
                                } else {
                                    return h( 'div', {
                                        style: {
                                            color: '#BE0B00',
                                            fontWeight: '600',
                                        },
                                    }, params.row.result );
                                }
                            },
                        },
                    ],
                },
            };
        },
        created () {
            this.fixedFc();
        },
        methods: {
            noIsLottery ( row, index ) {
                if ( this.data.order_info.prize_cash != 0 && row.select ) {
                    let params = row.select.split( ',' );
                    if ( !params.includes( row.result ) ) {
                        return 'noIsLottery';
                    } else {
                        return '';
                    }
                }
                return '';
            },
            fixedFc () {
                let flag = false;
                this.data.list.forEach( ( item, index ) => {
                    if ( item.fixed ) {
                        flag = true;
                        return;
                    }
                } );
                if ( !flag ) {
                    this.table.columns.splice( 4, 1 );
                }
                // return flag
            },
        },
    };
</script>
<style lang="less" scoped>
   .filter_plan{
        background: #F9FBFB;
        padding-bottom: 20%;
        color:#39444e;
        text-align: center;
        padding-top: 10%;
        img{
            display: inline-block;
        }
        p{
            margin-top: 10px;
        }
    }
    ::v-deep.ivu-table{
        border-right: 1px solid rgba(60, 69, 78, 0.8);}
    ::v-deep .noIsLottery {
        td {
            background: #EEEEEE; color: #a0a0b4;
        }
    }
    ::v-deep .ivu-table-row-hover {
        .zc-tag-page {
            background: transparent !important;
        }
    }
    ::v-deep.ivu-table td, .ivu-table th{
        height: 40px;
    }
</style>