var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tagsPage"},[(_vm.type == 2)?_c('span',{staticClass:"circleBtn",class:{ 
        'noBg': _vm.concede == 0 ,
        'red':_vm.concede > 0 
        }},[_vm._v(_vm._s(_vm.concede != '0'? _vm.concede: ''))]):_vm._e(),_c('el-row',{staticClass:"row",attrs:{"gutter":10}},[(_vm.type == 2)?_vm._l((_vm.btnList),function(item,index){return _c('el-col',{key:("btn-" + index),attrs:{"span":8}},[_c('button-group',{class:{
                        'btnNoborder': _vm.isLottery&&_vm.row.result_str!='延期'? ( !item.disabled && item.isPrize ): !item.disabled,
                        'isLottery': !item.disabled && _vm.isLottery
                    },attrs:{"size":"small"}},[_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"disabled":item.disabled,"type":"error"}},[_vm._v(_vm._s(item.name))]),(_vm.isLottery)?_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"disabled":item.disabled,"type":"default"}},[_vm._v(" "+_vm._s(( !item.disabled && item.isPrize )? item.price: ' ')+" ")]):_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"disabled":item.disabled,"type":"default"}},[_vm._v(_vm._s(item.price))])],1)],1)}):_vm._e(),(_vm.type == 3 || _vm.type == 4)?_vm._l((_vm.row.content),function(item,index){return _c('el-col',{key:("btn-" + index),attrs:{"span":8}},[_c('button-group',{class:{
                        'btnNoborder': _vm.playStatus >= 100 ? true : _vm.isLottery? item.desc == _vm.row.result_str: true,
                        'isLottery': ( item.desc != _vm.row.result_str && _vm.isLottery ), 'noFirstRow': index > 2
                    },attrs:{"size":"small"}},[(_vm.type == 3 || _vm.type == 4 )?_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"type":"error"}},[_vm._v(_vm._s(item.desc)+_vm._s(_vm.type == 3? '球':''))]):_vm._e(),(_vm.isLottery || _vm.playStatus >= 100)?_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"type":"default"}},[_vm._v(" "+_vm._s(_vm.playStatus >= 100 ? '1.00' : item.desc == _vm.row.result_str ? _vm.row.result : ' ')+" ")]):_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"type":"default"}},[_vm._v(_vm._s(item.odds))])],1)],1)}):_vm._e(),(_vm.type == 6)?_vm._l((_vm.row.content),function(item,index){return _c('el-col',{key:("btn-" + index),attrs:{"span":12}},[_c('button-group',{class:{
                        'btnNoborder': _vm.playStatus >= 100 ? true : _vm.isLottery? item.desc == _vm.row.result_str: true,
                        'isLottery': ( item.desc != _vm.row.result_str && _vm.isLottery ), 'noFirstRow': index > 1
                    },attrs:{"size":"small"}},[(_vm.type == 6)?_c('ivu-button',{staticStyle:{"width":"80px"},attrs:{"type":"error"}},[(item.desc == 'up_single')?[_vm._v("上单")]:_vm._e(),(item.desc == 'up_double')?[_vm._v("上双")]:_vm._e(),(item.desc == 'down_single')?[_vm._v("下单")]:_vm._e(),(item.desc == 'down_double')?[_vm._v("下双")]:_vm._e()],2):_vm._e(),(_vm.isLottery || _vm.playStatus >= 100)?_c('ivu-button',{staticStyle:{"width":"80px"},attrs:{"type":"default"}},[_vm._v(" "+_vm._s(_vm.playStatus >= 100 ? '1.00' : item.desc == _vm.row.result_str ? _vm.row.result : ' ')+" ")]):_c('ivu-button',{staticStyle:{"width":"80px"},attrs:{"type":"default"}},[_vm._v(_vm._s(item.odds))])],1)],1)}):_vm._e(),(_vm.type == 5)?_vm._l((_vm.row.content),function(item,index){return _c('el-col',{key:("btn-" + index),attrs:{"span":8}},[_c('button-group',{class:{
                        'btnNoborder': _vm.playStatus >= 100 ? true : _vm.isLottery? item.desc == _vm.row.result_str: true,
                        'isLottery': ( item.odds != _vm.row.result && _vm.isLottery ), 'noFirstRow': index > 2
                    },attrs:{"size":"small"}},[_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"type":"error"}},[_vm._v(_vm._s(item.desc.slice(0,1)+' / '+item.desc.slice(1)))]),(_vm.isLottery || _vm.playStatus >= 100)?_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"type":"default"}},[_vm._v(" "+_vm._s(_vm.playStatus >= 100 ? '1.00' : item.desc == _vm.row.result_str ? _vm.row.result : ' ')+" ")]):_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"type":"default"}},[_vm._v(_vm._s(item.odds))])],1)],1)}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }