<template>
    <div class="joint_detail" v-if="show">
        <el-collapse v-model="activeNames">
            <el-collapse-item name="1">
                <template slot="title">
                    <div class="title">
                        <h2>合买信息</h2>
                        <p>已认购<span>{{data.join_people}}人/{{data.join_count}}份</span></p>
                        <p>付款<span>{{data.join_money}}元</span></p>
                    </div>
                </template>
                <div class="time-select">
                    <div class="info">
                        <ul>
                            <li>
                                <div class="desc">
                                    <p>发起金额</p>
                                    <h2>{{data.buy_money}}元</h2>
                                </div>
                            </li>
                            <li>
                                <div class="desc">
                                    <p>发起份数</p>
                                    <h2>{{data.the_count}}份</h2>
                                </div>
                            </li>
                            <li>
                                <div class="desc">
                                    <p>单份金额</p>
                                    <h2>{{data.price}}元</h2>
                                </div>
                            </li>
                            <li>
                                <div class="desc">
                                    <p>保底份数</p>
                                    <h2>{{data.real_floor_count}}份</h2>
                                </div>
                            </li>
                            <li>
                                <div class="desc">
                                    <p>盈利奖励</p>
                                    <h2>{{data.profit}}%</h2>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="table" v-if="data.join_people_dt.length>0">
                        <table>
                            <thead>
                                <tr>
                                    <th width="200" style="text-align:left">备注名</th>
                                    <th width="200">参与金额</th>
                                    <th width="100">参与份数</th>
                                    <th width="300">参考奖金</th>
                                    <th width="200">实兑税后奖（兑入账本）</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in data.join_people_dt" :key="index" :bgcolor="index%2 != 0? '#F9FBFB': ''" >
                                    <td style="text-align:left" class="name">{{item.nickname}} <span v-if="item.join_sign == 2"> · 发起人</span></td>
                                    <td>{{item.buy_money}}元</td>
                                    <td>{{item.buy_count}}份</td>
                                    <td
                                    :class="{'tagRed' : item.refer_prized&&item.refer_prized>0?true:false}"
                                    >{{item.refer_prized?item.refer_prized:'----'}}
                                    <template v-if="item.refer_prized&&item.refer_prized>0?true:false">元</template>
                                    <span class="profit" v-if="item.profit_money>0">【盈利奖励{{item.profit_money}}元】</span>
                                    </td>
                                    <td
                                        :class="{'tagRed' : item.award_money&&item.award_money>0?true:false}"
                                    >{{item.award_money?item.award_money:'----'}}
                                    <template v-if="item.award_money&&item.award_money>0?true:false">元</template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
    export default {
        data () {
            return {
              activeNames: [ '1' ],
              data:null,
              user_id:'',
              show:false,
            };
        },
        created () {
            // this.joinPeopleDetail();
        },
        methods: {
            async joinPeopleDetail () {
                let params = {
                    plan_id: this.$route.query.order_id
                };
                const res = await this.HTTP.get( this.API.JOINPEOPLEDETAIL, params );
                if(res.data.code == 1){
                    this.data = res.data.data
                    this.show = this.data.is_union==1?true:false
                }else{
                    this.$message.error( res.data.msg );
                }
            },
            
        },
        components: {

            
        },
    };
</script>

<style lang="less" scoped>
@import '../../../../assets/css/common.less';
@margin10px: 10px;
@color: #00a79d;
.joint_detail{
    padding-top:38px;
    .title{
        display: flex;
        // border-bottom: 1px solid #D7E1F0;
        width:100%;
        h2{
            font-weight: normal;
            font-size: 14px;
            color:#4A4A4A;
            padding:0 20px 0 10px;
            // border-right: 1px solid #39444E;
            height:20px;
            font-weight: bold;
            line-height: 20px;
            margin-top: 15px;
            margin-right: 0px;
            position: relative;
            &:after{
                content: "";
                width:1px;
                height:14px;
                background: #39444E;
                margin-left:10px;
                display:inline-block;
                position: absolute;
                top:3px;
            }
        }
        p{
            font-size: 14px;
            color:#A0A0B4;
            margin-right: 10px;
            margin-left: 10px;
            span{
                color:#00a79d;
                font-weight: bold;
                margin-left: 5px;
            }
        }
    }
    .time-select{
        margin:0;
        .info{
            ul{
                display: flex;
                margin-top:20px;
                justify-content: space-between;
                li{
                    // flex: 1;
                    width:180px;
                    list-style: none;
                    display: flex;
                    border-bottom: 1px solid  rgba(0, 0, 0, 0.5);
                    .desc{
                        display: flex;
                        background: #F9F9F9;
                        justify-content: space-between;
                        align-items: center;
                        width:180px;
                        height: 60px;
                        padding:0px 10px;
                        p{
                            color:#A0A0B4;
                            font-size: 12px;
                        }
                        h2{
                            font-weight: normal;
                            
                            font-size: 20px;

                            color:#5A5A6E;
                        }
                    }
                }
            }
        }
    }
    .table{
        padding:27px 0 0;
        table{
            width:100%;
            border-collapse:collapse;
            tr{
                color:#4A4A4A;
                td{
                    font-size: 14px;
                    border-top:1px solid #D7E1F0;
                    text-align: center;
                    height:50px;
                    line-height: 50px;
                    padding-left: 10px;
                    &.name{
                        color:#00a79d;
                        span{
                            color:#4A4A4A;
                        }
                    }
                    &.tagRed{
                        color:#BE0B00;
                        font-weight: 600;
                        .profit{
                            color: #4A4A4A;
                            font-size: 12px;
                        }
                    }
                }
                th{
                    font-size: 14px;
                    height:50px;
                    line-height: 50px;
                    padding-left: 10px;
                }
            }
        }
    }
}
::v-deep.el-collapse{
    border-top:0;
}
::v-deep.el-collapse-item__header{
    border-bottom: 1px solid #D7E1F0;
}
::v-deep.el-collapse-item__content{
    padding-bottom: 0;
}
</style>