<template>
    <div class="stepPage" v-if="data">
        <div class="stepHead">
            <div class="head-left">
                <span class="nickname">{{ data.order_info.nickname }}</span>
                <span class="line"></span>
                <span class="span1">方案编号：</span>
                <span v-if="isB&&$route.query.src_order_id" class="span2">{{ $route.query.src_order_id }}</span>
                <span v-else class="span2">{{ $route.query.order_id }}</span>
                <span class="line"></span>
                <span class="span3" style="margin-left: 16px;">金额：</span>
                <span class="span4">￥{{ data.order_info.amount }}</span>
                <span class="span5">[ {{ data.order_info.multiple }}倍 ]</span>
                <!-- <span class="span6" v-if="isLottery">{{ isPrize }}</span> -->
                <span>{{data.order_info.B_show_prized_money}}</span>
            </div>
            <div class="head-right" v-if="!isLottery">
                <span class="span1">预计</span>
                <span class="span7">{{ common.format( data.order_info.last_match_over_time * 1000 ) }}</span>
                <span class="span3">开奖</span>
            </div>
            <div class="head-right" v-else></div>
        </div>
        <div class="stepBox">
            <el-steps :active="data.activeStep-1" align-center process-status="success" finish-status="finish">
                <el-step v-for="( item, index ) in data.order_schedule" :key="`step-${index}`">
                    <template slot="title">
                        <span :class="{ 'after': !isLottery && index == 3 }">{{ item.title }}</span>
                        <!-- <el-link type="primary" v-if="isLottery && index == 3">（请以实票奖金为准）</el-link> -->
                    </template>
                    <template slot="description">{{ item.desc }}</template>
                </el-step>
            </el-steps>
        </div>
    </div>
</template>

<script>
    import common from '@/utils/common';
    export default {
        props: {
            data: {
                required: true,
    
            },
        },
        data () {
            return {
                isLottery: this.data.order_info.prize_cash != 0,
                isPrize: '',
                common: common,
                isB: false,
            };
        },
        created () {
            this.getIsPrize();
            if ( window.localStorage.getItem( 'is_coop' ) == 1 && window.localStorage.getItem( 'auth' ) == 'partyB' ) {
                this.isB = true;
            }
        },
        methods: {
            getIsPrize () {
                switch ( this.data.order_info.prize_cash ) {
                    case 5:
                        this.isPrize = '未中奖';
                        break;
                    case 10:
                        this.isPrize = '已中奖';
                        break;
                    case 20:
                        this.isPrize = '已兑奖';
                        break;
                    case 30:
                        this.isPrize = '已中不兑';
                        break;
                    default:
                        this.isPrize = '未开奖';
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    @import '../../../assets/css/common.less';
    @color: #00a79d;
    .stepPage {
        .stepHead {
            .d-f-c; .p(16px); .bg(#f9fbfb); .c(@color); .fz(14px); justify-content: space-between;
            .head-left {
                .fz(14px); .fw(600); 
                .line {
                    display: inline-block; .h(12px); .w(1px); .bg(#39444e); .m(0 10px);
                }
                .span1, .span3 {
                    .c(#a0a0b4);
                }
                .span2,
                .span4,
                .span5 {
                    .c(#000);
                }
                .span5 {
                    .p(10px;);
                }
            }
            .span7 {
                .c(#f52323);
            }
        }
        .stepBox {
            .p(10px 0 10px 0); .bg(#fff);
            .activeSteps {
                .c;
            }
        }
        .after {
            .d-f-c; .d(inline-flex); .p(0 16px); .h(30px);
        }
        ::v-deep .el-step__icon{
            width: 25px;
            height: 25px;
        }
        ::v-deep .el-step__line{
            top: 9px !important;
        }
        ::v-deep .el-step__title{
            font-size: 14px;
        }
    }
</style>