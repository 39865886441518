<template>
    <div class="table-page">
        <ivuTable border :columns="table.columns" :data="data.plan_detail"></ivuTable>
    </div>
</template>

<script>
    import { Table as ivuTable } from 'iview';
    import tableTag from './tag';
    import { publicMixin } from './mixin/rankMixin';
    export default {
        mixins: [ publicMixin ],
        props: {
            data: {
                required: true,
            },
        },
        created () {
            this.data.plan_detail = [ { content: this.data.plan_detail } ];
            this.data.selectContent = this.getDataProcessing( this.data.plan_detail[ 0 ].content, this.type );
        },
        // eslint-disable-next-line complexity
        data () {
            let play_type = this.data.order_info.play_type;
            return {
                type: [ '7', '11' ].includes( this.$route.query.lottery_type ) && play_type == '1'
                    ? '3'
                    : [ '7', '11' ].includes( this.$route.query.lottery_type ) && play_type == '2'
                        ? '3-3'
                        : [ '7', '11' ].includes( this.$route.query.lottery_type ) && play_type == '3'
                            ? '3-6'
                            : this.$route.query.lottery_type == '8'
                                ? '5'
                                : this.$route.query.lottery_type == '9'
                                    ? '7'
                                    : '',
                table: {
                    columns: [
                        {
                            title: '彩期',
                            minWidth: 90,
                            align: 'center',
                            className: 'touzhuxiang',
                            render: ( h, params ) => h( 'div', `${this.data.order_info.qishu}` ),
                        },
                        {
                            title: '投注选项',
                            align: 'center',
                            minWidth: 500,
                            className: 'touzhuxiang',
                            render: ( h, params ) => h( tableTag, {
                                props: {
                                    row: params.row,
                                    isLottery: this.data.order_info.prize_cash != 0,
                                    data: this.data,
                                    rankZuType: play_type == '1' ? '1' : play_type == '2' ? '3-3' : '3-6',
                                },
                                style: {
                                    paddingTop: '10px',
                                },
                            } ),
                        },
                        {
                            title: '彩果',
                            align: 'center',
                            minWidth: 200,
                            className: 'spzhi',
                            render: ( h, params ) => {
                                if ( this.data.order_info.prize_cash === 0 ) {
                                    return h( 'div', {
                                        style: {
                                            width: '32px',
                                            height: '2px',
                                            background: 'rgba( 74, 74, 74, 1 )',
                                            borderRadius: '1px',
                                            margin: '0 auto',
                                        },
                                    } );
                                } else {
                                    return h( tableTag, {
                                        props: {
                                            resultFlag: true,
                                            isLottery: false,
                                            row: params.row,
                                            data: this.data,
                                            rankZuType: play_type == '2' ? '3-3' : '3-6',
                                        },
                                    } );
                                }
                            },
                        },
                    ],
                },
            };
        },
        components: {
            tableTag, ivuTable,
        },
    };
</script>
<style lang='less' scoped>
  

</style>