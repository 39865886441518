<template>
    <div class="zc-tag-page" :class="{ 'isLottery': isLottery && row.select.length != 0, 'lottery': row.select.includes( result ) }">
        <span
            v-for="( item, index ) in row.select"
            :key="`betting-${ index }`"
            :class="{ 'noFirstItem': index > 0 ,
            'fontWeight':result === item
            }"
            :style="{ color: result === item? '#BE0B00': '#515a6e' }">
            {{ item }}
        </span>
    </div>
</template>

<script>
    export default {
        props: [ 'row', 'isLottery', 'type', 'result' ],
        created () {
            this.row.select = this.row.select ? this.row.select.split( ',' ) : [];
        },
    };
</script>

<style lang="less" scoped>
    .zc-tag-page {
        margin: 0 -18px;
        padding: 5px 0;
        &.isLottery{
            background: #EEEEEE; color: #a0a0b4;
        }
        &.lottery {
            background: #fff;
        }
        .noFirstItem {
            margin-left: 8px;
        }
        .fontWeight{
            font-weight: 600;
        }
    }
</style>