<template>
    <div class="tagsPage">
        <span class="circleBtnf" :class="concede > 0 ? 'circleBtn red' : 'circleBtn'" v-if="type == 2">{{ concede }}</span>
        <span class="circleBtn" v-else></span>
        <!-- 
            play_type:1是竞足胜平负
            play_type:2是竞足让球胜负
            play_type:3：竞足总进球
            play_type:4竞足比分
            play_type:5竞足全半场
            play_type:6竞足混合过关
        -->
        <el-row :gutter="16" class="row">
            <template v-if="type == 1 || type == 2">
                <el-col :span="8" v-for="( item, index ) in btnList" :key="`btn-${index}`">
                    <ButtonGroup size="small" :class="{ 'btnNoborder': isLottery? ( !item.disabled && item.isPrize ): !item.disabled, 'isLottery': !item.disabled && isLottery }">
                        <ivuButton :disabled="item.disabled" style="width: 48px;" type="error">{{ item.name }}</ivuButton>
                        <ivuButton :disabled="item.disabled" style="width: 48px;" type="default">{{ item.price }}</ivuButton>
                    </ButtonGroup>
                </el-col>
            </template>
            <template v-if="type == 3 || type == 4">
                <el-col :span="8" v-for="( item, index ) in row.content" :key="`btn-${index}`">
                    <ButtonGroup
                        size="small"
                        :class="{
                            'btnNoborder': playStatus >= 100 ? true : isLottery? item.desc == row.result_str: true,
                            'isLottery': ( item.desc != row.result_str && isLottery ),
                            'noFirstRow': index > 2 
                        }">
                        <ivuButton style="width: 48px;" type="error">{{ item.desc }}{{ type == 3? '球': ''}}</ivuButton>
                        <ivuButton style="width: 48px;" type="default">
                            {{
                                playStatus >= 100
                                    ? '1.00'
                                    : item.odds
                            }}
                        </ivuButton>
                    </ButtonGroup>
                </el-col>
            </template>
            <template v-if="type == 5">
                <el-col :span="8" v-for="( item, index ) in row.content" :key="`btn-${index}`">
                    <ButtonGroup size="small"
                        :class="{
                            'btnNoborder': playStatus >= 100 ? true : isLottery ? item.desc == row.result_str: true,
                            'isLottery': ( item.desc != row.result_str && isLottery ),
                            'noFirstRow': index > 2
                        }">
                        <ivuButton style="width: 48px;" type="error">{{ item.desc.slice(0,1)+' / '+item.desc.slice(1) }}</ivuButton>
                        <ivuButton style="width: 48px;" type="default">
                            {{
                                playStatus >= 100
                                    ? '1.00'
                                    : item.odds
                            }}
                        </ivuButton>
                    </ButtonGroup>
                </el-col>
            </template>
        </el-row>
    </div>
</template>

<script>
    import { ButtonGroup, Button as ivuButton } from 'iview';
    export default {
        props: [ 'row', 'concede', 'isLottery', 'type', 'playStatus' ],
        data () {
            return {
                btnList: [],
            };
        },
        created () {
            this.row.content = JSON.parse( this.row.content );

            // eslint-disable-next-line default-case
            switch ( this.type ) {
                case 1:
                    this.isActiveBtn();
                    break;
                case 2:
                    this.isActiveBtn();
                    break;
            }

        },
        methods: {
            isActiveBtn () {
                this.btnList = [
                    { name: '胜', price: this.row.src_odds_data.win, disabled: true, isPrize: false },
                    { name: '平', price: this.row.src_odds_data.draw, disabled: true, isPrize: false },
                    { name: '负', price: this.row.src_odds_data.lose, disabled: true, isPrize: false },
                ];
                let activeArr = [];
                this.row.content.map( ( item ) => activeArr.push( item.desc ) );
                if ( activeArr.includes( 'win' ) ) {
                    this.btnList[ 0 ].disabled = false;
                }
                if ( activeArr.includes( 'draw' ) ) {
                    this.btnList[ 1 ].disabled = false;
                }
                if ( activeArr.includes( 'lose' ) ) {
                    this.btnList[ 2 ].disabled = false;
                }

                if ( this.isLottery ) {
                    if ( this.row.result_str == 'win' ) {
                        this.btnList[ 0 ].isPrize = true;
                    }
                    if ( this.row.result_str == 'draw' ) {
                        this.btnList[ 1 ].isPrize = true;
                    }
                    if ( this.row.result_str == 'lose' ) {
                        this.btnList[ 2 ].isPrize = true;
                    }
                }
                if ( this.playStatus >= 100 ) {
                    this.btnList.map( ( item ) => {
                        if ( !item.disabled ) {
                            item.isPrize = true;
                            item.price = '1.00';
                        }
                    } );
                }
            },
        },
        components: {
            ButtonGroup, ivuButton,
        },
    };
</script>

<style lang="less" scoped>
    .tagsPage {
        padding: 10px 0;
        display: flex;
        .row {
            flex: 1;
        }
        .circleBtn{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 22px;
            height: 26px;
            color: #00a79d;
            margin-right: 16px;
            font-weight: 600;
            // background: #EEEEEE;
        }
        .circleBtnf{
            background: #EEEEEE;
        }
        .red {
            color: (#BE0B00);
        }
        .noFirstRow {
            margin-top: 8px;
        }
        ::v-deep .el-col-8{
            width: 31%;
            padding: 0 !important;
        }
    }
</style>