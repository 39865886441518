var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tagsPage"},[_c('el-row',{staticClass:"row",attrs:{"gutter":10,"justify":"center"}},[[_c('el-col',{attrs:{"span":8}},[_c('button-group',{class:{
                        'btnNoborder': _vm.isLottery&&_vm.row.result_str!='延期'? ( !_vm.btnList[0].disabled && _vm.btnList[0].isPrize ): !_vm.btnList[0].disabled,
                        'isLottery': !_vm.btnList[0].disabled && _vm.isLottery
                    },attrs:{"size":"small"}},[_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"disabled":_vm.btnList[0].disabled,"type":"error"}},[_vm._v(_vm._s(_vm.btnList[0].name))]),(_vm.isLottery)?_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"disabled":_vm.btnList[0].disabled,"type":"default"}},[_vm._v(" "+_vm._s(( !_vm.btnList[0].disabled && _vm.btnList[0].isPrize )? _vm.btnList[0].price: ' ')+" ")]):_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"disabled":_vm.btnList[0].disabled,"type":"default"}},[_vm._v(_vm._s(_vm.btnList[0].price))])],1)],1),_c('el-col',{attrs:{"span":4}},[_c('div',{staticClass:"concede"},[_c('span',{staticClass:"circleBtn",class:{ 
                    'noBg': _vm.concede == 0 ,
                    'red':_vm.concede > 0 
                    }},[_vm._v(_vm._s(_vm.concede != '0'? _vm.concede: ''))]),_vm._v(" "+_vm._s(_vm.concede_desc)+" ")])]),_c('el-col',{attrs:{"span":8}},[_c('button-group',{class:{
                        'btnNoborder': _vm.isLottery&&_vm.row.result_str!='延期'? ( !_vm.btnList[1].disabled && _vm.btnList[1].isPrize ): !_vm.btnList[1].disabled,
                        'isLottery': !_vm.btnList[1].disabled && _vm.isLottery
                    },attrs:{"size":"small"}},[_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"disabled":_vm.btnList[1].disabled,"type":"error"}},[_vm._v(_vm._s(_vm.btnList[1].name))]),(_vm.isLottery)?_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"disabled":_vm.btnList[1].disabled,"type":"default"}},[_vm._v(" "+_vm._s(( !_vm.btnList[1].disabled && _vm.btnList[1].isPrize )? _vm.btnList[1].price: ' ')+" ")]):_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"disabled":_vm.btnList[1].disabled,"type":"default"}},[_vm._v(_vm._s(_vm.btnList[1].price))])],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }