<template>
	<div class="modifyBj" @click="hide">
		<img class="img1" :src="imgurl" @click.stop="" />
	</div>
</template>
<script>
	export default {
		props: ['imgurl'],
		data() {
			return {}
		},
		methods: {
			hide(){
				this.$emit('hideImg')
			}
		},
        mounted() {
        },
	}
</script>
<style lang="less" scoped>
.modifyBj{  z-index: 10000; }
.img1{ width: 750px; height: auto; }
</style>