/**
 * 辅助性js
 */
// 时间戳转换
export function getTime(num) {
	num = parseInt(num)
	let len = (num + '').length;
	let data = "";
	if (len == 10) {
		data = num * 1000;
	} else {
		data = num;
	}	
	var date = new Date(data); //格式化时间戳,变为国际标准模式  Fri Apr 19 2019 13:42:34 GMT+0800 (中国标准时间)
	let Y = date.getFullYear() + "-";
	let M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
	let D = date.getDate() + " ";
	let h = date.getHours() + ':';
	let m = date.getMinutes() + ':';
	let s = date.getSeconds();
	return Y + M + D + h + m + s;
}
