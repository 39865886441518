<template>
    <div class="hhjcTable">
        <ivuTable :row-class-name="rowClassName" :columns="columns" :data="data" :show-header="false"></ivuTable>
    </div>
</template>

<script>
    import { Table as ivuTable } from 'iview';
    import tableTag from './tag';
    export default {
        props: [ 'row', 'concede', 'isLottery', 'type', 'allRed' ],
        data () {
            return {
                columns: [
                    {
                        title: 'Name',
                        className: 'hhtable',
                        render: ( h, params ) => h( tableTag, {
                            props: {
                                row: params.row,
                                concede: this.concede,
                                isLottery: this.isLottery,
                                type: params.row.odds_type,
                                allRed: this.allRed,
                            },
                        } ),
                    },
                ],
                data: null,
            };
        },
        created () {
            let arr = [];
            let keyArr = [ 'odds_base', 'odds_concede', 'odds_big_little', 'odds_difference' ];
            for ( let key in this.row ) {
                if ( keyArr.includes( key ) ) {
                    arr.push( this.row[ key ] );
                }
            }
            this.data = arr;
        },
        methods: {
            rowClassName ( row, index ) {
                if ( index === this.data.length - 1 ) {
                    return 'last-child-row';
                }
                return '';
            },
        },
        components: {
            ivuTable,
            tableTag,
        },
    };
</script>