export const planMixin = {
    data () {
        return {

        };
    },
    methods: {

    },
};
