<template>
    <div class="table-page">
        <ivuTable border :columns="table.columns" :data="data.plan_detail"></ivuTable>
    </div>
</template>

<script>
    import { Table as ivuTable } from 'iview';
    import tableTag from './tag';
    export default {
        props: {
            data: {
                required: true,
            },
        },
        created () {
            this.data.plan_detail = [ { content: this.data.plan_detail } ];
        },
        data () {
            let type = this.data.order_info.play_type;
            return {
                table: {
                    columns: [
                        {
                            title: '彩期',
                            minWidth: 90,
                            align: 'center',
                            className: 'touzhuxiang',
                            render: ( h, params ) => h( 'div', `${this.data.order_info.qishu}` ),
                        },
                        {
                            title: '投注选项',
                            align: 'center',
                            minWidth: 500,
                            className: 'touzhuxiang',
                            render: ( h, params ) => {
                                let selectRedArr = [];
                                let selectBlueArr = [];
                                if ( this.data.order_info.prize_cash != 0 ) {
                                    let newArr = this.data.result.split( ',' );
                                    selectRedArr = this.$route.query.lottery_type == 6?newArr.slice( 0, 5 ):newArr.slice( 0, 6 );
                                    selectBlueArr = this.$route.query.lottery_type == 6?newArr.slice( 5 ):newArr.slice( 6 )
                                }
                                return h( tableTag, {
                                    props: {
                                        row: params.row,
                                        isLottery: this.data.order_info.prize_cash != 0,
                                        select: {
                                            selectRedArr: selectRedArr,
                                            selectBlueArr: selectBlueArr,
                                        },
                                    },
                                } );
                            },
                        },
                        {
                            title: '彩果',
                            align: 'center',
                            minWidth: 200,
                            className: 'spzhi',
                            render: ( h, params ) => {
                                if ( this.data.order_info.prize_cash === 0 ) {
                                    return h( 'div', '未开奖' );
                                } else {
                                    let newArr = this.data.result.split( ',' );
                                    let row = {
                                        content: [
                                            {
                                                red: this.$route.query.lottery_type == 6?newArr.slice( 0, 5 ):newArr.slice( 0, 6 ),
                                                blue: this.$route.query.lottery_type == 6?newArr.slice( 5 ):newArr.slice( 6 )
                                            },
                                        ],
                                    };
                                    return h( tableTag, {
                                        props: {
                                            row: row,
                                            isLottery: false,
                                            select: {
                                                selectRedArr: [],
                                                selectBlueArr: [],
                                            },
                                        },
                                    } );
                                }
                            },
                        },
                    ],
                },
            };
        },
        components: {
            tableTag, ivuTable,
        },
    };
</script>