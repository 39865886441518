<template>
    <div v-if="data" class="echo">
        <template>
            <div class="plan-head">
                <div class="stepPage">
                    <div class="stepHead">
                        <div class="planAuto stepHead1">
                            <div class="head-left">
                                <span class="nickname">{{data.order_info.nickname}}</span>
                                <span class="line"></span>
                                <span class="span1">方案编号：</span>
                                <span class="span2">{{data.order_info.order_num}}</span>
                                <span class="line"></span>
                                <span class="span3" style="margin-left: 16px;">金额：</span>
                                <span class="span4">￥{{data.order_info.money}}</span>
                                <span class="span5">[ {{data.order_info.multiple}}倍 ]</span>
                                <span>{{data.order_info.B_show_prized_money}}</span>
                            </div>
                            <div class="head-right" v-if="!isLottery">
                                <span class="span1">预计</span>
                                <span class="span7">{{ backTime( data.order_info.last_match_over_time * 1000 ) }}</span>
                                <span class="span3">开奖</span>
                            </div>
                            <div class="head-right" v-else></div>
                        </div>
                    </div>
                    <div class="stepBox planAuto">
                        <el-steps :active="data.activeStep-1" align-center process-status="success" finish-status="finish">
                            <el-step v-for="( item, index ) in data.order_schedule" :key="`step-${index}`">
                                <template slot="title">
                                    <span :class="{ 'after': !isLottery && index == 3 }">{{ item.title }}</span>
                                    <!-- <el-link type="primary" v-if="isLottery && index == 3">（请以实票奖金为准）</el-link> -->
                                </template>
                                <template slot="description">{{ item.desc }}</template>
                            </el-step>
                        </el-steps>
                    </div>
                </div>
            </div>
            <div class="planDetails">
                <div class="plan-table">
                    <div class="table-head">
                        <div class="table-head-left col-b">
                            <span class="span1">{{ data.order_info.lottery_play_explain }}</span>
                            <span class="line"></span>
                            <span class="span2">第</span>
                            <span class="span2">{{ data.order_info.qishu }}</span>
                            <span class="span4">期</span>
                            <span class="line"></span>
                            <template v-if="data.order_info.follow_no > 1">
                                <span class="span2">追</span>
                                <span class="span2">{{ data.order_info.follow_no }}</span>
                                <span class="span4">期</span>
                            </template>
                        </div>
                        <div class="table-head-right col-b" v-if="!init.rankHead.includes( data.order_info.lottery_type )">
                            <span class="span1">方案截止时间：</span>
                            <span class="span2 theme-color">{{ data.order_info.print_end_time }}</span>
                        </div>
                    </div>
                </div>
                <table class="select-five-table" v-if="data.order_info.follow_no == 1">
                    <thead>
                        <tr>
                            <th width="240">彩期</th>
                            <th width="452">投注项</th>
                            <th>彩果</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="border-left: 1px solid rgba(60,69,78,0.8);">{{data.order_info.qishu}}</td>
                            <td>
                                <ul>
                                    <li v-for="(item,index) in data.plan_detail" :key="index">
                                        <div v-if="item.dan.length>0" class="dan-list content">
                                            <span>胆 :&nbsp; </span> 
                                            <input class="item-btn circle" :class="{'selection':selected(item1,index1)}"   v-for="( item1, index1 ) in item.dan" type="button" :value="item1>9?item1:'0'+item1" :key="index1" />
                                        </div>
                                        <div class="normal-list">
                                            <span class="normal-list-item" v-for="( item2, index2 ) in item.num" :key="index2">
                                                <input class="item-btn circle"  :class="{'selection':selected(item4,index2)}"  v-for="( item4, index4 ) in item2" type="button" :value="item4>9?item4:'0'+item4" :key="index4" />
                                                <em v-if="item.dan.length == 0 && item2.length > 0"> | </em>
                                            </span> 
                                        </div>
                                    </li>
                                </ul>
                            </td>
                            <td style="border-right: 1px solid rgba(60,69,78,0.8);"
                            v-if="data.order_info.follow_result[0].syy_result.length>0 && data.order_info.prize_cash > 0">
                                <input class="item-btn selection" v-for="( item3, index3 ) in data.order_info.follow_result[0].syy_result" type="button" :value="item3>9?item3:'0'+item3" :key="index3" />
                            </td>
                            <td v-else style="border-right: 1px solid rgba(60,69,78,0.8);">
                                ----
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="select-five-table  zhuiqi-table" v-if="data.order_info.follow_no != 1">
                <!-- <table class="select-five-table  zhuiqi-table" v-if="data.flag != 1"> -->
                    <thead>
                        <tr>
                            <th width="398">投注项</th>
                            <th width="169">追号-{{data.order_info.follow_no}}期</th>
                            <th width="232">彩果</th>
                            <th>中奖金额</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="border-left: 1px solid rgba(60,69,78,0.8);">
                                <ul>
                                    <li v-for="(item,index) in data.plan_detail" :key="index">
                                        <div v-if="item.dan.length>0" class="dan-list content">
                                            <span>胆 :&nbsp; </span> 
                                            <input class="item-btn circle selection" v-for="( item1, index1 ) in item.dan" type="button" :value="item1>9?item1:'0'+item1" :key="index1" />
                                        </div>
                                        <div class="normal-list">
                                            <span class="normal-list-item" v-for="( item2, index2 ) in item.num" :key="index2">
                                                <input class="item-btn circle selection"   v-for="( item4, index4 ) in item2" type="button" :value="item4>9?item4:'0'+item4" :key="index4" />
                                                <em v-if="item.dan.length == 0 && item2.length > 0"> | </em>
                                            </span> 
                                        </div>
                                    </li>
                                </ul>
                            </td>
                            <td style="border-right: 1px solid rgba(60,69,78,0.8);border-bottom: 1px solid rgba(60,69,78,0.8);">
                                <ul class="qihao-list">
                                    <li  class="qihao-list-item" v-for="(item6,index6) in data.order_info.follow_result" :key="index6" style="position:relative;">
                                        <template v-if="item6.result>0">
                                            <span class="f12" style=" 
                                                width:0;
                                                height:0;
                                                border-width:26px 26px 0 0;
                                                border-style:solid;
                                                border-color:#F55E55FF transparent transparent transparent;
                                                position:absolute;
                                                color:#000;
                                                line-height:-10px;
                                                left:0;
                                                top:0;">
                                            </span>
                                            <span class='f12 f14' style="
                                                position: absolute; 
                                                left: 2px;
                                                top: 1px;
                                                color: #fff;">
                                                中
                                            </span>
                                        </template>
                                        {{item6.qishu}}
                                    </li>
                                </ul>
                            </td>
                            <td style="border-right: 1px solid rgba(60,69,78,0.8);border-bottom: 1px solid rgba(60,69,78,0.8);">
                                <ul class="qihao-list">
                                    <li  class="qihao-list-item" v-for="(item7,index7) in data.order_info.follow_result" :key="index7">
                                    <template v-if="item7.syy_result.length>0"><input class="item-btn selection" v-for="( item8, index8 ) in item7.syy_result" type="button" :value="item8>9?item8:'0'+item8" :key="index8" /></template> 
                                    <template v-else> ---- </template>
                                    </li>
                                </ul>
                            </td>
                            <td style="border-right: 1px solid rgba(60,69,78,0.8);">
                                <ul class="qihao-list">
                                    <li  class="qihao-list-item" :class="{'result-color':item9.syy_result.length>0 && item9.result>0}" v-for="(item9,index9) in data.order_info.follow_result" :key="index9">
                                        <template v-if="item9.syy_result.length>0 && item9.result>0 && data.order_info.prize_cash>0">{{item9.result}}</template>
                                        <template v-else-if="item9.syy_result.length>0 && item9.result==0 && data.order_info.prize_cash>0"> 未中 </template>
                                        <template v-else> ---- </template>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
       <!-- <template v-if="$route.name == 'ticketDetail'">
          <ticketDetail/>
       </template>    -->
    </div>
</template>

<script>
    import {getTime} from '@/../static/help';
    import mySteps from '../step';
    import ticketDetail from '../ticketDetail';
    import { Divider } from 'iview';
    export default {
        props: {
            data: {
                required: true,
            },
        },
        data () {
            return {
                activeName: '选号详情',
                init: {
                    list: [
                        { name: '选号详情' },
                        { name: '出票详情' },
                    ],
                    detailHead: [ 1, 2, 4, 5 ],
                    rankHead: [ 7, 8, 9, 11 ],
                    qishuHead: [ 3, 6, 12 ],
                },
                data: null,
                typeFlag: false,
                isLottery: false,
                paly_type: 0,
                resultArr: [],
                isOpentime: false,
            };
        },
        created () {
        },
        methods: {
            // 1  '乐选二   2  '乐选三   3 '乐选四   4 '乐选五   5  '任选二   6 '任选三   7  '任选四  8 '任选五
            // 9 '任选六   10  '任选七   11 '任选八  12  '前一'  13  '前二组选  15'前三组选  16 '前三直选',
            selected ( item, index ) {
                let result = this.data.order_info.follow_result[ 0 ];
                let flag = false;
                if ( !this.isOpentime ) {
                    flag = true;
                } else {
                    if ( this.resultArr.length > 0 ) {
                        if ( this.paly_type == 1 || this.paly_type == 2 || this.paly_type == 12 || this.paly_type == 14 || this.paly_type == 16 ) {
                            if ( item == result.syy_result[ index ] ) {
                                flag = true;
                            }
                        } else {
                            if ( this.paly_type == 13 ) {
                                let qianerArr = [];
                                qianerArr = result.syy_result.slice( 0, 2 );
                                if ( qianerArr.indexOf( item ) > -1 ) {
                                    flag = true;
                                }
                            } else if ( this.paly_type == 15 ) {
                                let qiansanArr = [];
                                qiansanArr = result.syy_result.slice( 0, 3 );
                                if ( qiansanArr.indexOf( item ) > -1 ) {
                                    flag = true;
                                }
                            } else {
                                if ( result.syy_result.indexOf( item ) > -1 ) {
                                    flag = true;
                                }
                            }
                        }
                    }
                }
                return flag;
            },
            backTime(timeStr){
                getTime(timeStr)
            }
        },
        components: {
            mySteps, ticketDetail,
        },
    };
</script>

<style lang="less" scoped>
@import '../../../../assets/css/common.less';
@margin10px: 10px;
@color: #00a79d;
.echo{
    width:100%!important;
}
.planAuto{
    width: 1000px;
    margin: 0 auto;
}
.stepHead1{
    .d-f-c; .p(16px); .bg(#f9fbfb); .c(@color); .fz(14px); justify-content: space-between;
}
    .stepPage {
        .stepHead {
            .bg(#f9fbfb);
            // .d-f-c; .p(16px); .bg(#f9fbfb); .c(@color); .fz(14px); justify-content: space-between;
            .head-left {
                .fz(14px); .fw(600);
                .line {
                    display: inline-block; .h(12px); .w(1px); .bg(#39444e); .m(0 10px);
                }
                .span1,
                .span3 {
                    .c(#a0a0b4);
                }
                .span2,
                .span4,
                .span5 {
                    .c(#000);
                }
                .span5 {
                    .p(10px;);
                }
            }
            .span7 {
                .c(#f52323);
            }
        }
        .stepBox {
            .p(10px 0 10px 0); .bg(#fff);
            .activeSteps {
                .c;
            }
        }
        .after {
            .d-f-c; .d(inline-flex); .p(0 16px); .h(30px);
        }
        ::v-deep .el-step__icon{
            width: 25px;
            height: 25px;
        }
        ::v-deep .el-step__line{
            top: 9px !important;
        }
        ::v-deep .el-step__title{
            font-size: 14px;
        }
    }
	.planDetails {
		width: 1000px;
		margin: 0 auto;
		margin-bottom: 100px;
		.plan-head {
			background: rgb(248, 248, 248);
		}
		.plan-table {
			font-size: 14px;
			margin-bottom: 0;
			background-color: #39444e;
			color: #fff;
			.table-head {
				line-height: 40px;
				display: flex;
				justify-content: space-between;
				margin: 0 30px;
				.table-head-left {
					.span2,
					.span5 {
						margin-left: 8px;
					}
				}
			}
			.theme-color {
				color: #00a79d;
			}
		}
		.line {
			display: inline-block;
			height: 12px;
			background-color: #fff;
			width: 1px;
			margin: 0 10px;
		}
    }
    .select-five-table{
        height: 100%;
        width:100%;
        border-collapse: collapse;
        thead{
            tr{
                th{
                    text-align: center;
                    height: 40px;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
        tbody{
            height: 100%;
            tr{
                td{
                    border-top: 1px solid #39444E;
                    border-bottom: 1px solid #39444E;
                    text-align: center;
                    border-right: 1px solid #39444E;
                    background: #F9FBFB;
                    height: 100%;
                    ul{
                        li{
                            list-style: none;
                            border-top: 1px solid #39444E;
                            .dan-list{
                                height: 48px;
                                display: flex;
                                align-items: center;
                                padding:0 16px;
                                span{}
                                .item-btn {
                                    .w( 25px );.h( 25px );.mr( 2px ); .bg( #fff );
                                    border: 1px solid #FFCDCD;
                                    font-size: 14px;
                                    font-weight: 600;
                                    color: #FF5961;
                                    border-radius: 50%;
                                }
                            }
                            .normal-list{
                                height: 48px;
                                display: flex;
                                align-items: center;
                                padding:0 16px 0 37px;
                                .normal-list-item{
                                    display: flex;
                                    align-items: center;
                                    .item-btn{
                                        .w( 25px );.h( 25px );.mr( 2px ); .bg( #fff );
                                        border: 1px solid #FFCDCD;
                                        font-size: 14px;
                                        font-weight: 600;
                                        color: #BE0B00;;
                                        border-radius: 50%;
                                    }
                                    em{
                                        // width: 2px;
                                        // height: 26px;
                                        // background: #FF5961;
                                        display: inline-block;
                                        opacity: 0.2183;
                                        color:#BE0B00;;
                                        font-size: 24px;
                                        margin: 0px 8px 0 6px;
                                    }
                                    &:last-child{
                                        em{
                                            display: none;
                                        }
                                    }
                                }
                            }
                            &:first-child{
                                border-top: 0;
                            }
                        }
                    }
                    .item-btn{
                        .w( 25px );.h( 25px );.mr( 2px ); .bg( #fff );
                        border: 1px solid #FFCDCD;
                        font-size: 14px;
                        font-weight: 600;
                        color: #BE0B00;;
                        border-radius: 50%;
                    }
                    &:last-child{
                        border-right: 0;
                    }
                    .qihao-list{
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .qihao-list-item{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            // border-bottom: 1px solid rgba(60,69,78,0.8);
                            flex: 1;
                            min-height: 48px;
                            &:first-child{
                                border-top:0;
                            }
                            &.result-color{
                                color:#BE0B00;;
                            }
                        }
                    }
                }
            }
        }
        &.zhuiqi-table{
             tbody{
                tr{
                    td{
                       border-right: 1px solid #D7E1F0; 
                       &:first-child{
                           border-color:#39444E;
                       }
                       &:last-child{
                           border-right:0;
                       }
                    }
                }
             }
        }
    }
    .selection{
        color:#fff!important;
        background:#BE0B00!important;
    }
</style>