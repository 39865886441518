<template>
    <div class="lotto-selct-tag">
        <ul class="list">
            <li v-for="( item, index ) in row.content" :key="`btn-${ index }`" :class="{ 'noLastLi': index != row.content.length - 1 }">
                <span v-if="item.redbravery? item.redbravery.length != 0: false">
                    [ 胆：<circleBtn :list="item.redbravery" type="success" :ghost="isLottery" :select="select.selectRedArr"/>]
                </span>
                <span> <circleBtn :list="item.red" type="success" :ghost="isLottery" :select="select.selectRedArr"/></span>
                <span v-if="item.bluebravery? item.bluebravery.length != 0: false">
                    [ 胆：<circleBtn :list="item.bluebravery" :ghost="isLottery" :select="select.selectBlueArr"/>]
                </span>
                <span> <circleBtn :list="item.blue" :ghost="isLottery" :select="select.selectBlueArr"/></span>
            </li>
        </ul>
    </div>
</template>

<script scoped>
    import circleBtn from './circle';
    export default {
        props: [ 'row', 'isLottery', 'select' ],
        components: {
            circleBtn,
        },
    };
</script>

<style lang="less" scoped>
    @import '~@/assets/css/common.less';
    .lotto-selct-tag{
        .t-c( left ); .m( 0 -16px ); .p( 8px );
        .list {
            li {
                list-style: none;
                &.noLastLi {
                    .mb( 0 );
                }
            }
        }
    }
</style>