/*
* @Author: qq757513343
* @Date:   2017-11-16 16:06:01
 * @Last Modified by: wy 461542524@qq.com
 * @Last Modified time: 2019-12-04 10:35:50
*/
import { Message } from 'element-ui';

function doHandleMonth ( month ) {
    var m = month;
    if ( month.toString().length == 1 ) {
        m = '0' + month;
    }
    return m;
}
export default {
    alertMsg ( c, m ) {
        if ( c == '200' || c == 1 ) {
            Message( {
                message: m || '信息提交成功',
                type: 'success',
            } );
        } else {
            Message.error( m || '信息提交失败' );
        }
    },
    // 浏览器音频兼容
    autioFlex () {
        var myAudio = document.createElement( 'audio' );
        if ( myAudio.canPlayType ) {
            if ( myAudio.canPlayType( 'audio/mpeg' ) != '' ) {
                return 'mp3';
            }
            if ( myAudio.canPlayType( 'audio/ogg;codecs="vorbis"' ) != '' ) {
                return 'ogg';
            }
            if ( myAudio.canPlayType( 'audio/mp4;codecs="mp4a.40.5"' ) != '' ) {
                return 'acc';
            }
        } else {
            alert( '您的浏览器不支持要检测的音频格式。' );
        }
    },
    dothis ( v ) {
        s = s.replace( /\D/g, '' );
        if ( v.value == '' ) {
            return false;
        }
        // 小写转大写;
        v.value = v.value.toUpperCase();
        // 判断是否为英文
        if ( !/^([A-Za-z]+\s?)*[A-Za-z]$/.test( v.value ) ) {
            return v.value = v.value.substr( 0, v.value.length - 1 );
        }
        // 判断是否超过长度
        if ( v.value.length > 20 ) {
            return v.value = v.value.substr( 0, 19 );
        }

        var reg = /[^A-Za-z]/g;
        if ( reg.test( obj.value ) ) {
            obj.value = '';
            return false;
        } else {
            obj.value = obj.value.toUpperCase();
            return true;
        }
    },
    // 浅拷贝
    extendCopy ( p ) {
        var c = {};
        for ( var i in p ) {
            c[ i ] = p[ i ];
        }
        c.uber = p;
        return c;
    },
    // 金额格式化
    toThousands ( s, n ) {
        if ( s == '' || s == null ) {
            return '0.00';
        } else {
            n = n > 0 && n <= 20 ? n : 2;
            s = parseFloat( ( s + '' ).replace( /[^\d\.-]/g, '' ) ).toFixed( n ) + '';
            var l = s.split( '.' )[ 0 ].split( '' ).reverse(), r = s.split( '.' )[ 1 ];
            var t = '';
            for ( var i = 0; i < l.length; i++ ) {
                t += l[ i ] + ( ( i + 1 ) % 3 == 0 && i + 1 != l.length ? ',' : '' );
            }
            return t.split( '' ).reverse()
                .join( '' ) + '.' + r;
        }
    },
    // 百分比
    toPercent ( point ) {
        var str = Number( point * 100 ).toFixed( 2 );
        str += '%';
        return str;
    },
    // 判断是否小于9加0 日期调用
    add0 ( m ) {
        return m < 10 ? '0' + m : m;
    },
    dateFormater ( formater, t ) {
        let date = t ? new Date( t ) : new Date(),
            Y = date.getFullYear() + '',
            M = date.getMonth() + 1,
            D = date.getDate(),
            H = date.getHours(),
            m = date.getMinutes(),
            s = date.getSeconds();
        return formater.replace( /YYYY|yyyy/g, Y )
            .replace( /YY|yy/g, Y.substr( 2, 2 ) )
            .replace( /MM/g, ( M < 10 ? '0' : '' ) + M )
            .replace( /DD/g, ( D < 10 ? '0' : '' ) + D )
            .replace( /HH|hh/g, ( H < 10 ? '0' : '' ) + H )
            .replace( /mm/g, ( m < 10 ? '0' : '' ) + m )
            .replace( /ss/g, ( s < 10 ? '0' : '' ) + s );
        // dateFormater('YYYY-MM-DD HH:mm', t) ==> 2019-06-26 18:30
        // dateFormater('YYYYMMDDHHmm', t) ==> 201906261830
    },
    format ( sjc ) {
        let time = new Date( sjc );
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        // 年月日
        // return y + '-' + this.add0( m ) + '-' + this.add0( d );
        // 年月日时分秒
        return y + '-' + this.add0( m ) + '-' + this.add0( d ) + ' ' + this.add0( h ) + ':' + this.add0( mm ) + ':' + this.add0( s );
    },
    format_y_m_d ( sjc ) {
        let time = new Date( sjc );
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        // 年月日
        return y + '-' + this.add0( m ) + '-' + this.add0( d );
    },
    formatMonth ( sjc ) {
        let time = new Date( sjc );
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        // 年月
        return y + '-' + this.add0( m );
        // 年月日时分秒
        // return y+'-'+this.add0(m)+'-'+this.add0(d)+' '+this.add0(h)+':'+this.add0(mm)+':'+this.add0(s);
    },    
    /**
     * [getDay 获取当前日期]
     * @param  {[type]} [day]   [ 日期 ]
     * @return {[type]}         [ yyyy-mm-dd ]
     */
    getDay ( day ) {
        var today = new Date();
        var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
        today.setTime( targetday_milliseconds ); // 注意，这行是关键代码
        var tYear = today.getFullYear();
        var tMonth = today.getMonth();
        var tDate = today.getDate();
        tMonth = doHandleMonth( tMonth + 1 );
        tDate = doHandleMonth( tDate );
        return tYear + '-' + tMonth + '-' + tDate;
    },
    time_range ( beginTime, endTime ) {
        var strb = beginTime.split( ':' );
        if ( strb.length != 2 ) {
            return false;
        }

        var stre = endTime.split( ':' );
        if ( stre.length != 2 ) {
            return false;
        }

        var b = new Date();
        var e = new Date();
        var n = new Date();

        b.setHours( strb[ 0 ] );
        b.setMinutes( strb[ 1 ] );
        e.setHours( stre[ 0 ] );
        e.setMinutes( stre[ 1 ] );

        if ( n.getTime() - b.getTime() > 0 && n.getTime() - e.getTime() < 0 ) {
            // alert ("当前时间是：" + n.getHours () + ":" + n.getMinutes () + "，在该时间范围内！");
            return true;
        } else {
            // alert ("当前时间是：" + n.getHours () + ":" + n.getMinutes () + "，不在该时间范围内！");
            return false;
        }
    },
    // 限制正整数
    limitInt ( s ) {
        s = s.replace( /\D/g, '' );
        return s;
    },
    // 只能输入数字且小数点为2位
    clearNoNum ( sum ) {
        if ( sum != '' && sum.substr( 0, 1 ) == '.' ) {
            sum = '';
        }
        sum = sum.replace( /[^\d.]/g, '' );
        sum = sum.replace( /\.{2,}/g, '.' );
        sum = sum.replace( '.', '$#$' ).replace( /\./g, '' )
            .replace(
                '$#$', '.' );
        sum = sum.replace( /^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3' );
        if ( sum.indexOf( '.' ) < 0 && sum != '' ) {
            if ( sum.substr( 0, 1 ) == '0' && sum.length == 2 ) {
                sum = sum.substr( 1, sum.length );
            }
        }
        return sum;
    },
    // input限制最大值和最小值默认值
    inputMaxMindefaultValue ( s, max, min, defaultValue ) {
        s = s.replace( /\D/g, '' );
        if ( s > max || s < min ) {
            s = defaultValue;
        }
        return s;
    },
    // input限制最大值和最小值
    inputMaxMin ( s, max, min ) {
        s = s.replace( /\D/g, '' );
        if ( s > max || s < min ) {
            s = '';
        }
        return s;
    },
    // input限制百分比
    inputBFB ( s, max, min ) {
        s = s.replace( /\D/g, '' );
        if ( s > max || s < min ) {
            s = '';
        }
        return s;
    },
    // 金额正则
    moneyMatching ( rule, value, callback ) {
        if ( !value ) {
            callback();
        } else if ( !/(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test( value ) ) {
            return callback( new Error( '您输入的金额格式不正确' ) );
        } else {
            callback();
        }
    },
    // 验证正整数
    verificationInt ( rule, value, callback ) {
        if ( !value ) {
            return callback( new Error( '不能为空' ) );
        } else if ( !/^[0-9]+$/.test( value ) ) {
            return callback( new Error( '您输入的格式不正确' ) );
        } else {
            callback();
        }
    },
    // 验证正整数 区间 2 ~ 500
    verificationInt ( rule, value, callback ) {
        if ( !/^[0-9]+$/.test( value ) ) {
            return callback( new Error( '您输入的金额格式不正确' ) );
        } else if ( value < 1 || value > 500 ) {
            return callback( new Error( '您输入的金额区间不正确' ) );
        } else {
            callback();
        }
    },
    // 验证正整数并且限制大小
    verIntMM ( rule, value, callback, msg, max, min ) {
        if ( !value ) {
            return callback( new Error( msg + '不能为空' ) );
        } else if ( !/^[0-9]+$/.test( value ) ) {
            return callback( new Error( '您输入的' + msg + '格式不正确' ) );
        } else if ( value < max && value > mix ) {
            return callback( new Error( '您输入的' + msg + '区间不正确，应为：' + min + '-' + max ) );
        } else {
            callback();
        }
    },
    // 校验密码强度（^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,10}$）
    // 验证身份证
    idCard ( rule, value, callback ) {
        let iSum = 0;

        /* 省份验证 */
        let aCity = {11: '北京', 12: '天津', 13: '河北', 14: '山西', 15: '内蒙古', 21: '辽宁', 22: '吉林', 23: '黑龙江', 31: '上海', 32: '江苏', 33: '浙江', 34: '安徽', 35: '福建', 36: '江西', 37: '山东', 41: '河南', 42: '湖北', 43: '湖南', 44: '广东', 45: '广西', 46: '海南', 50: '重庆', 51: '四川', 52: '贵州', 53: '云南', 54: '西藏', 61: '陕西', 62: '甘肃', 63: '青海', 64: '宁夏', 65: '新疆', 71: '台湾', 81: '香港', 82: '澳门', 91: '国外'};
        let v = String( value ).replace( /x$/i, 'a' );

        /* 出生日期验证 */
        let b = value.substr( 6, 4 ) + '-' + Number( value.substr( 10, 2 ) ) + '-' + Number( value.substr( 12, 2 ) );
        let bir = new Date( b.replace( /-/g, '/' ) );

        /* 校验位错误验证 */
        let code = value.split( '' );
        // 加权因子
        let factor = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ];
        // 校验位
        let parity = [ 1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2 ];
        let sum = 0, ai = 0, wi = 0;
        for ( let i = 0; i < 17; i++ ) {
            ai = code[ i ];wi = factor[ i ];sum += ai * wi;
        }
        let last = parity[ sum % 11 ];
        if ( !value ) {
            return callback( new Error( '身份证号不能为空' ) );
        } else if ( !/^\d{17}(\d|x)$/i.test( value ) ) {
            return callback( new Error( '您输入的身份证长度或格式错误' ) );
        } else if ( aCity[ parseInt( v.substr( 0, 2 ) ) ] == null ) {
            return callback( new Error( '您输入的身份证地区非法' ) );
        } else if ( b != bir.getFullYear() + '-' + ( bir.getMonth() + 1 ) + '-' + bir.getDate() ) {
            return callback( new Error( '您输入的身份证上的出生日期非法' ) );
        } else if ( parity[ sum % 11 ] != code[ 17 ].toUpperCase() ) {
            return callback( new Error( '您输入的身份证号非法' ) );
            // aCity[parseInt(value.substr(0,2))]+","+b+","+(value.substr(16,1)%2?"男":"女");//此次还可以判断出输入的身份证号的人性别
        } else {
            callback();
        }
    },
    phone ( rule, value, callback ) {
        if ( !value ) {
            return callback( new Error( '手机号码不能为空' ) );
        } else if ( !/^0?(13|14|15|17|18|19)[0-9]{9}$/.test( value ) ) {
            return callback( new Error( '您输入的手机号码格式不正确' ) );
        } else {
            callback();
        }
    },
    phoneNew ( rule, value, callback ) {
        if ( !value ) {
            return callback( new Error( '手机号码不能为空' ) );
        } else if ( !/^1\d{10}$/.test( value ) ) {
            return callback( new Error( '您输入的手机号码格式不正确' ) );
        } else {
            callback();
        }
    },
    // 校验中文名称
    chinese ( rule, value, callback ) {
        if ( !value ) {
            return callback( new Error( '该项不能为空' ) );
        } else if ( !/^[\\u4e00-\\u9fa5]{0,}$/.test( value ) ) {
            return callback( new Error( '您输入的名称格式不正确' ) );
        } else {
            callback();
        }
    },
    // 由数字、26个英文字母或下划线组成的字符串(^\\w+$)
    // 判断IE的版本(^.*MSIE [5-8](?:\\.[0-9]+)?(?!.*Trident\\/[5-9]\\.0).*$)
    // 校验E-Mail 地址
    email ( rule, value, callback ) {
        if ( !value ) {
            return callback( new Error( '邮箱地址不能为空' ) );
        } else if ( !/^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/.test( value ) ) {
            return callback( new Error( '您输入的邮箱地址格式不正确' ) );
        } else {
            callback();
        }
    },
    // 校验金额
    money ( rule, value, callback ) {
        if ( !value ) {
            return callback( new Error( '该项不能为空' ) );
        } else if ( !/^[0-9]+(.[0-9]{2})?$/.test( value ) ) {
            return callback( new Error( '您输入的金额格式不正确' ) );
        } else {
            callback();
        }
    },
    // 转换成是时分秒
    timeChange ( intDiff ) {
        let day = 0,
            hour = 0,
            minute = 0,
            second = 0;// 时间默认值
        if ( intDiff > 0 ) {
            // day = Math.floor(intDiff / (60 * 60 * 24));
            hour = Math.floor( intDiff / ( 60 * 60 ) ) - day * 24;
            minute = Math.floor( intDiff / 60 ) - day * 24 * 60 - hour * 60;
            second = Math.floor( intDiff ) - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60;
        }
        if ( minute <= 9 ) {minute = '0' + minute;}
        if ( second <= 9 ) {second = '0' + second;}
        // $('#day_show').html(day+"天");
        // $('#hour_show').html('<s id="h"></s>'+hour+'时');
        // $('#minute_show').html('<s></s>'+minute+'分');
        // $('#second_show').html('<s></s>'+second+'秒');
        intDiff--;
        return hour + ':' + minute + ':' + second;
    },
    // 时分秒倒计时
    timer ( intDiff ) {
        // setInterval(() => {
        let day = 0,
            hour = 0,
            minute = 0,
            second = 0;// 时间默认值
        if ( intDiff > 0 ) {
            // day = Math.floor(intDiff / (60 * 60 * 24));
            hour = Math.floor( intDiff / ( 60 * 60 ) ) - day * 24;
            minute = Math.floor( intDiff / 60 ) - day * 24 * 60 - hour * 60;
            second = Math.floor( intDiff ) - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60;
        }
        if ( minute <= 9 ) {minute = '0' + minute;}
        if ( second <= 9 ) {second = '0' + second;}
        return hour + ':' + minute + ':' + second;
        // }, 1000);
    },

    /**
     * 阿拉伯数字转中文数字,
     * 如果传入数字时则最多处理到21位，超过21位js会自动将数字表示成科学计数法，导致精度丢失和处理出错
     * 传入数字字符串则没有限制
     * @param {number|string} digit
     */
    toZhDigit ( digit ) {
        digit = typeof digit === 'number' ? String( digit ) : digit;
        const zh = [ '零', '一', '二', '三', '四', '五', '六', '七', '八', '九' ];
        const unit = [ '千', '百', '十', '' ];
        const quot = [ '万', '亿', '兆', '京', '垓', '秭', '穰', '沟', '涧', '正', '载', '极', '恒河沙', '阿僧祗', '那由他', '不可思议', '无量', '大数' ];

        let breakLen = Math.ceil( digit.length / 4 );
        let notBreakSegment = digit.length % 4 || 4;
        let segment;
        let zeroFlag = [],
            allZeroFlag = [];
        let result = '';

        while ( breakLen > 0 ) {
            if ( !result ) { // 第一次执行
                segment = digit.slice( 0, notBreakSegment );
                let segmentLen = segment.length;
                for ( let i = 0; i < segmentLen; i++ ) {
                    if ( segment[ i ] != 0 ) {
                        if ( zeroFlag.length > 0 ) {
                            result += '零' + zh[ segment[ i ] ] + unit[ 4 - segmentLen + i ];
                            // 判断是否需要加上 quot 单位
                            if ( i === segmentLen - 1 && breakLen > 1 ) {
                                result += quot[ breakLen - 2 ];
                            }
                            zeroFlag.length = 0;
                        } else {
                            result += zh[ segment[ i ] ] + unit[ 4 - segmentLen + i ];
                            if ( i === segmentLen - 1 && breakLen > 1 ) {
                                result += quot[ breakLen - 2 ];
                            }
                        }
                    } else {
                        // 处理为 0 的情形
                        if ( segmentLen == 1 ) {
                            result += zh[ segment[ i ] ];
                            break;
                        }
                        zeroFlag.push( segment[ i ] );
                        continue;
                    }
                }
            } else {
                segment = digit.slice( notBreakSegment, notBreakSegment + 4 );
                notBreakSegment += 4;
                for ( let j = 0; j < segment.length; j++ ) {
                    if ( segment[ j ] != 0 ) {
                        if ( zeroFlag.length > 0 ) {
                            // 第一次执行zeroFlag长度不为0，说明上一个分区最后有0待处理
                            if ( j === 0 ) {
                                result += quot[ breakLen - 1 ] + zh[ segment[ j ] ] + unit[ j ];
                            } else {
                                result += '零' + zh[ segment[ j ] ] + unit[ j ];
                            }
                            zeroFlag.length = 0;
                        } else {
                            result += zh[ segment[ j ] ] + unit[ j ];
                        }
                        // 判断是否需要加上 quot 单位
                        if ( j === segment.length - 1 && breakLen > 1 ) {
                            result += quot[ breakLen - 2 ];
                        }
                    } else {
                        // 第一次执行如果zeroFlag长度不为0, 且上一划分不全为0
                        if ( j === 0 && zeroFlag.length > 0 && allZeroFlag.length === 0 ) {
                            result += quot[ breakLen - 1 ];
                            zeroFlag.length = 0;
                            zeroFlag.push( segment[ j ] );
                        } else if ( allZeroFlag.length > 0 ) {
                            // 执行到最后
                            if ( breakLen == 1 ) {
                                result += '';
                            } else {
                                zeroFlag.length = 0;
                            }
                        } else {
                            zeroFlag.push( segment[ j ] );
                        }

                        if ( j === segment.length - 1 && zeroFlag.length === 4 && breakLen !== 1 ) {
                            // 如果执行到末尾
                            if ( breakLen === 1 ) {
                                allZeroFlag.length = 0;
                                zeroFlag.length = 0;
                                result += quot[ breakLen - 1 ];
                            } else {
                                allZeroFlag.push( segment[ j ] );
                            }
                        }
                        continue;
                    }
                }
                --breakLen;
            }
            return result;
        }
    },

    /**
     * [bankNumber 银行卡号效验]
     * @param  {[type]} intDiff [description]
     * @return {[type]}         [description]
     */
    bankNumber ( rule, value, callback ) {
        if ( !value ) {
            return callback( new Error( '银行卡号不能为空' ) );
        } else if ( !/^\d{15,21}$/.test( value ) ) {
            return callback( new Error( '您输入的银行卡号不正确' ) );
        } else {
            callback();
        }
    },

    /**
     * [numberVol 数字效验]
     * @param  {[type]}   rule     [description]
     * @param  {[type]}   value    [description]
     * @param  {Function} callback [description]
     * @return {[type]}            [description]
     */
    numberVol ( rule, value, callback ) {
        if ( !value ) {
            return callback( new Error( '该项不能为空' ) );
        } else if ( !/^[0-9]*$/.test( value ) ) {
            return callback( new Error( '您输入的内容格式不正确' ) );
        } else {
            callback();
        }
    },

    /**
     * [numIntegerVol 正整数效验]
     * @param  {[type]}   rule     [description]
     * @param  {[type]}   value    [description]
     * @param  {Function} callback [description]
     * @return {[type]}            [description]
     */
    numIntegerVol ( rule, value, callback ) {
        if ( !value ) {
            return callback( new Error( '该项不能为空' ) );
        } else if ( !/^(0|[1-9][0-9]*|-[1-9][0-9]*)$/.test( value ) ) {
            return callback( new Error( '您输入的内容必须为正整数' ) );
        } else {
            callback();
        }
    },

    /**
     * [moneyVol 金额效验]
     * @param  {[type]}   rule     [description]
     * @param  {[type]}   value    [description]
     * @param  {Function} callback [description]
     * @return {[type]}            [description]
     */
    moneyVol ( rule, value, callback ) {
        if ( !value ) {
            return callback( new Error( '该项不能为空' ) );
        } else if ( !/(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/.test( value ) ) {
            return callback( new Error( '您输入的金额格式不正确' ) );
        } else {
            callback();
        }
    },

    isPhone ( value ) {
        return {
            valid: /^0?(13|14|15|17|18|19)[0-9]{9}$/.test( value ),
            msg: '手机号格式不正确',
        };
    },
    isIdNo ( value ) {
        return {
            valid: /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/.test( value ),
            msg: '身份证号格式不正确',
        };
    },
    isCode ( value ) {
        return {
            valid: value.length === 6,
            msg: '验证码格式不正确',
        };
    },
    isNoOne ( value ) {
        return {
            valid: /^[\s\S]*.*[^\s][\s\S]*$/.test( value ),
            msg: '该项不能为空',
        };
    },
    isAllPass ( value ) {
        return {
            valid: value != null,
            msg: '该项格式不正确',
        };
    },
};
