<template>
    <div class="hhjcTable">
        <ivuTable :row-class-name="rowClassName" :columns="tableType === 'result'? resultColumns: duizhenColumns" :data="data" :show-header="false"></ivuTable>
    </div>
</template>

<script>
    import { Table as ivuTable } from 'iview';
    export default {
        props: [ 'row', 'concede', 'isLottery', 'type', 'tableType' ],
        data () {
            return {
                resultColumns: [
                    {
                        title: 'Name',
                        align: 'center',
                        // eslint-disable-next-line complexity
                        render: ( h, params ) => {
                            let str = '';
                            let type = params.row.odds_type;
                            let resultStr = params.row.result_str;
                            let tdHeight = 10;
                            if ( ( type == 1 || type == 2 ) && resultStr != '' ) {
                                if ( resultStr == 'guest_win' ) {
                                    str = '客胜';
                                } else if ( resultStr == 'home_win' ) {
                                    str = '主胜';
                                }
                            } else if ( type == 3 && resultStr != '' ) {
                                if ( resultStr == 'little' ) {
                                    str = '小分';
                                } else {
                                    str = '大分';
                                }
                            } else if ( type == 4 && resultStr != '' ) {
                                if ( resultStr.search( 'lose-' ) != -1 ) {
                                    str = resultStr.replace( /\lose-/g, '客胜' );
                                } else {
                                    str = resultStr.replace( /\win-/g, '主胜' );
                                }
                            } else if ( type == 5 && resultStr != '' ) {
                                str = resultStr.slice( 0, 1 ) + ' / ' + resultStr.slice( 1 );
                            } else {
                                str = '';
                            }
                            if ( type == 4 ) {
                                let rowContent = 2;
                                let listLength = JSON.parse( params.row.content ).length;
                                if ( listLength > rowContent ) {
                                    let row = parseInt( listLength % rowContent === 0 ? listLength / rowContent - 1 : listLength / rowContent );
                                    tdHeight = tdHeight + row * 25 + row * 8 + row * rowContent;
                                }
                            }
                            return h( 'div', {
                                attrs: {
                                    class: 'verticalCenter',
                                },
                                style: {
                                    color: 'red',
                                    height: `${tdHeight}px`,
                                },
                            }, str );
                        },
                    },
                ],
                duizhenColumns: [
                    {
                        title: 'Name',
                        align: 'center',
                        render: ( h, params ) => {
                            let str = '';
                            if ( this.isLottery ) {
                                str = `${this.row.match_info.kedui} : ${this.row.match_info.zhudui}`;
                            } else {
                                str = 'VS';
                            }
                            return h( 'div', [
                                h( 'span', this.row.match_info.kedui_name ),
                                h( 'span', {
                                    style: {
                                        color: this.isLottery ? 'red' : 'pink',
                                        margin: '0 8px',
                                    },
                                }, str ),
                                h( 'span', this.row.match_info.zhudui_name ),
                            ] );
                        },
                    },
                ],
                data: null,
            };
        },
        created () {
            let arr = [];
            let keyArr = [ 'odds_base', 'odds_concede', 'odds_big_little', 'odds_difference' ];
            for ( let key in this.row ) {
                if ( keyArr.includes( key ) ) {
                    arr.push( this.row[ key ] );
                }
            }
            this.data = arr;
        },
        methods: {
            rowClassName ( row, index ) {
                if ( index === this.data.length - 1 ) {
                    return 'last-child-row';
                }
                return '';
            },
        },
        components: {
            ivuTable,
        },
    };
</script>