var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tagsPage"},[_c('el-row',{staticClass:"row",attrs:{"gutter":16}},[(_vm.type == 1)?_vm._l((_vm.btnList),function(item,index){return _c('el-col',{key:("btn-" + index),attrs:{"span":item.span}},[(index != 1)?_c('ButtonGroup',{class:{
                        'btnNoborder': _vm.isLottery? ( !item.disabled && item.isPrize ) || _vm.allRed: !item.disabled,
                        'isLottery': !item.disabled && _vm.isLottery
                    },attrs:{"size":"small"}},[_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"disabled":item.disabled,"type":"error"}},[_vm._v(_vm._s(item.name))]),_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"disabled":item.disabled,"type":"default"}},[_vm._v(_vm._s(item.price))])],1):_c('div',[_vm._v(" ")])],1)}):_vm._e(),(_vm.type == 2)?_vm._l((_vm.btnList),function(item,index){return _c('el-col',{key:("btn-" + index),attrs:{"span":item.span}},[(index != 1)?_c('ButtonGroup',{class:{
                        'btnNoborder': _vm.isLottery? ( !item.disabled && item.isPrize ) || _vm.allRed: !item.disabled,
                        'isLottery': !item.disabled && _vm.isLottery
                    },attrs:{"size":"small"}},[_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"disabled":item.disabled,"type":"error"}},[_vm._v(_vm._s(item.name))]),_c('ivu-button',{staticStyle:{"width":"48px"},attrs:{"disabled":item.disabled,"type":"default"}},[_vm._v(_vm._s(item.price))])],1):_c('ivu-button',{staticStyle:{"width":"48px"},style:({color: item.price < 0? '#009688 !important': '#F57A23 !important'}),attrs:{"disabled":item.disabled,"size":"small","type":"default"}},[_vm._v(" 主 "+_vm._s(item.price)+" ")])],1)}):_vm._e(),(_vm.type == 3)?_vm._l((_vm.row.content),function(item,index){return _c('el-col',{key:("btn-" + index),attrs:{"span":item.span}},[(index != 1)?_c('ButtonGroup',{class:{
                        'btnNoborder': item.redBorder || _vm.allRed,
                        'isLottery': item.gray
                    },attrs:{"size":"small"}},[_c('ivuButton',{staticStyle:{"width":"48px"},attrs:{"disabled":_vm.isLottery? ( !item.redBorder && !item.gray ) :true,"type":"error"}},[_vm._v(_vm._s(item.desc))]),_c('ivuButton',{staticStyle:{"width":"48px"},attrs:{"disabled":_vm.isLottery? ( !item.redBorder && !item.gray ) :false,"type":"default"}},[_vm._v(_vm._s(item.odds))])],1):_c('ivuButton',{staticStyle:{"width":"48px","color":"#F57A23 !important"},attrs:{"size":"small","type":"default"}},[_vm._v(" "+_vm._s(item.extend)+" ")])],1)}):_vm._e(),(_vm.type == 4)?_vm._l((_vm.row.content),function(item,index){return _c('el-col',{key:("jcbf-" + index),staticClass:"jclqSfc",attrs:{"span":12}},[_c('ButtonGroup',{class:{
                        'btnNoborder': _vm.isLottery? item.desc != _vm.row.result_str: false,
                        'isLottery': ( item.desc == _vm.row.result_str && _vm.isLottery ) || _vm.allRed || !_vm.isLottery,
                        'noFirstRow': index > 2
                    },attrs:{"size":"small"}},[_c('ivuButton',{staticStyle:{"width":"69px"},attrs:{"type":"error"}},[_vm._v(_vm._s(item.desc))]),_c('ivuButton',{staticStyle:{"width":"60px"},attrs:{"type":"default"}},[_vm._v(_vm._s(item.odds))])],1)],1)}):_vm._e(),(_vm.type == 5)?_vm._l((_vm.row.content),function(item,index){return _c('el-col',{key:("btn-" + index),attrs:{"span":8}},[_c('ButtonGroup',{staticClass:"btnNoborder",class:{
                        'btnNoborder': _vm.isLottery? item.odds != _vm.row.result || _vm.allRed: true,
                        'isLottery': ( item.odds == _vm.row.result && _vm.isLottery ),
                        'noFirstRow': index > 2
                    },attrs:{"size":"small"}},[_c('ivuButton',{staticStyle:{"width":"72px"},attrs:{"type":"error"}},[_vm._v(_vm._s(item.desc.slice(0,1)+' / '+item.desc.slice(1)))]),_c('ivuButton',{staticStyle:{"width":"72px"},attrs:{"type":"default"}},[_vm._v(_vm._s(item.odds))])],1)],1)}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }