<template>
    <div class="table-page">
        <ivuTable border :columns="table.columns" :data="data.plan_detail"></ivuTable>
    </div>
</template>

<script>
    import { Table as ivuTable } from 'iview';
    import tableTag from './tag';
    import hhjcTable from './hhjcTable';
    import resultStr from './resule';
    import common from '@/utils/common';
    export default {
        props: {
            data: {
                required: true,
            },
        },
        data () {
            let type = this.data.order_info.play_type;
            let tableKey = '';
            // eslint-disable-next-line default-case
            switch ( type ) {
                case 1:
                    tableKey = 'odds_base';
                    break;
                case 2:
                    tableKey = 'odds_handicap';
                    break;
                case 3:
                    tableKey = 'odds_goals';
                    break;
                case 4:
                    tableKey = 'odds_point';
                    break;
                case 5:
                    tableKey = 'odds_half_whole';
                    break;
            }
            return {
                table: {
                    columns: [
                        {
                            title: '序号',
                            minWidth: 68,
                            className: 'touzhuxiang',
                            align: 'center',
                            render: ( h, params ) => {
                                let num = common.toZhDigit( params.row.match_info.match_turn.substring( 0, 1 ) );
                                let str = `周${num == '七' ? '日' : num}`;
                                return h( 'div', `${str}${params.row.match_info.match_turn.substring( 1 )}` );
                            },
                        },
                        {
                            title: '时间',
                            minWidth: 90,
                            className: 'touzhuxiang',
                            align: 'center',
                            render: ( h, params ) => h( 'div', params.row.match_info.start_time.substring( 5 ) ),
                        },
                        {
                            title: '对阵',
                            minWidth: 257,
                            className: 'touzhuxiang',
                            align: 'center',
                            render: ( h, params ) => {
                                let str = '';
                                if ( params.row.match_info.status == 50 ) {
                                    str = `${params.row.match_info.zhujin}:${params.row.match_info.kejin}`;
                                } else if ( params.row.match_info.status >= 100 ) {
                                    str = '延期';
                                } else {
                                    str = ' VS ';
                                }
                                return h( 'div', [
                                    h( 'span', {
                                        style: {
                                            width: '75px', display: 'inline-block', textAlign: 'right',
                                        },
                                    }, params.row.match_info.zhudui_name ),
                                    h( 'span', {
                                        style: {
                                            color: params.row.match_info.status == 50 || params.row.match_info.status >= 100
                                                ? '#BE0B00'
                                                : '#999',
                                            margin: '0 3px', width: '35px', display: 'inline-block',
                                            fontWeight: '600',
                                        },
                                    }, str ),
                                    h( 'span', {
                                        style: {
                                            width: '75px', display: 'inline-block', textAlign: 'left',
                                        }}, params.row.match_info.kedui_name ),
                                ] );
                            },
                        },
                        {
                            title: '投注项',
                            align: 'center',
                            minWidth: 370,
                            className: 'touzhuxiang',
                            render: ( h, params ) => {
                                let jcArr = [ 1, 2, 3, 4, 5 ];
                                if ( jcArr.includes( type ) ) {
                                    return h( tableTag, {
                                        props: {
                                            row: params.row[ tableKey ],
                                            concede: params.row.match_info.concede,
                                            isLottery: params.row.match_info.status == '50',
                                            type: this.data.order_info.play_type,
                                            playStatus: params.row.match_info.status,
                                        },
                                    } );
                                } else {
                                    return h( hhjcTable, {
                                        props: {
                                            row: params.row,
                                            concede: params.row.match_info.concede,
                                            isLottery: params.row.match_info.status == '50',
                                            type: this.data.order_info.play_type,
                                            playStatus: params.row.match_info.status,
                                        },
                                    } );
                                }
                            },
                        },
                        {
                            title: '彩果',
                            align: 'center',
                            className: 'spzhi',
                            minWidth: 70,
                            // eslint-disable-next-line complexity
                            render: ( h, params ) => {
                                if ( type != 6 ) {
                                    let str = '';
                                    let winLostDraw = [ 1, 2 ];
                                    if ( params.row.match_info.status == '50' ) {
                                        if ( winLostDraw.includes( type ) ) {
                                            if ( params.row[ tableKey ].result_str == 'win' ) {
                                                str = '胜';
                                            } else if ( params.row[ tableKey ].result_str == 'lose' ) {
                                                str = '负';
                                            } else if ( params.row[ tableKey ].result_str == 'draw' ) {
                                                str = '平';
                                            }
                                        } else if ( type == 3 && params.row[ tableKey ].result_str != '' ) {
                                            str = params.row[ tableKey ].result_str + '球';
                                        } else if ( type == 4 ) {
                                            str = params.row[ tableKey ].result_str;
                                        } else if ( type == 5 && params.row[ tableKey ].result_str != '' ) {
                                            str = params.row[ tableKey ].result_str.slice( 0, 1 ) + ' / ' + params.row[ tableKey ].result_str.slice( 1 );
                                        }
                                    }
                                    if ( params.row.match_info.status >= 100 ) {
                                        str = '延期';
                                    }
                                    return h( 'div', {
                                        style: {
                                            color: '#BE0B00', fontWeight: '600',
                                        },
                                    }, str );
                                } else {
                                    return h( resultStr, {
                                        props: {
                                            row: params.row,
                                            concede: params.row.match_info.concede,
                                            type: this.data.order_info.play_type,
                                            isLottery: params.row.match_info.status == '50',
                                        },
                                    } );
                                }
                            },
                        },
                    ],
                },
            };
        },
        components: {
            tableTag, ivuTable, resultStr,
        },
    };
</script>