<template>
    <div class="tickDetail" v-if="tickets">
           <div class="print-title">
               <div class="head-left">
                <span class="nickname a79">
                    <span v-if="data.nickname">{{ data.nickname }}</span>
                        <span v-else>{{ data.mobile }}</span>
                    </span>
                <span class="line"></span>
                <span class="span1">方案编号：</span>
                <span class="span2">{{ data.order_num }}</span>
                <span class="line"></span>
                <span class="span3" style="margin-left: 16px;">金额：</span>
                <span class="span4">￥{{ data.amount }}&nbsp;/{{ data.ticket }}</span>
                <!-- <span class="span1" style="margin-left: 16px;">奖金:</span>
                <span class="span5">{{ data.money }}</span><span style="color:red">（请以实票奖金为准）</span> -->
            </div>
        </div>
        <div class="table-head" >
          <div class="table-head-left col-b">
                    <span class="span1">{{ data.lottery_name }}</span>
                    <span class="line1"></span>
                    <template>
                        <template v-if="data.lottery_type == 2">
                            <span class="span2">第</span>
                            <span class="">{{ data.qishu }}</span>
                            <span class="span4">期</span>
                            <span class="line1"></span>
                        </template>
                        <span class="span2">选</span>
                        <span class="span3">{{ data.ticket }}</span>
                        <span class="span4">场</span>
                    </template>
                </div>
                <div class="table-head-right col-b">
                    <span class="span1">方案截止时间：</span>
                    <span class="span2 theme-color">{{ data.print_end_time }}</span>
                </div>
            </div>
          <div class="print-content">
                <table class="admintableprint">
                    <thead>
                        <tr>
                            <th>序号</th>
                            <th>倍数</th>
                            <th>金额</th>
                            <th>过关</th>
                            <th style="text-align:left;padding-left:5px">投注内容</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, listIndex) in tickets" :key="listIndex">
                            <td>{{ item.sn }}</td>
                            <td>{{ item.wagerMultiple }}</td>
                            <td>{{ item.money / 100 }}</td>
                            <td>{{ item.playType == 'P'? '直选':( item.playType == 'PA'? '追加':(item.playType == 'C'?'组选':item.playType.replace(/\*/g,'串')) ) }}</td>
                            <td style="text-align:left;padding-left:5px">{{ item.wager }}</td>
                        </tr>
                    </tbody>
                </table>
        </div>
    </div>
</template>

<script  scoped>
    export default {
    
        data () {
            return {
                tickets: null,
                data: {},
            };
        },
        created () {
            this.TicketList();
            this.getTicketDetail();
        },
        methods: {
            async TicketList ( ) {
                const res = await this.HTTP.get( `${this.API.TicketList}${this.$route.query.order_id}/${localStorage.getItem( 'access_token' )}?print_type=bet_machine` );
                this.tickets = res.data.data.tickets;
            },
            async getTicketDetail () {
                let params = {
                    order_id: this.$route.query.order_id,
                    user_id: this.$route.query.user_id,
                    lottery_type: this.$route.query.lottery_type,
                    from_shop: '1',
                };
                const res = await this.HTTP.get( this.API.TicketDetail, params );
                this.data = res.data.data.order_info;
            },
        },
    };
</script>

<style lang="less" scoped>
@import '../../../assets/css/common.less';
@width: 1000px;
@color: #00a79d;
.h ( @h: 0 ) {
    height: @h;
}

@beforeWidth: 10px;
@borLeftHeight: 40px;
@borWidth: 6px;


    .admintableprint {
        width: 1000px;
        font-size: 14px;
        border-collapse: collapse;
        color: #000;
        margin-bottom: 10px;
        background:rgba(249,251,251,1);
    }
    .admintableprint th {
        height: 48px;
        background-color: #fff;
        font-size: 14px;
    }
    td,
    th {
        padding: 0;
    }
    .admintableprint tr{
        border: 1px solid rgba(60,69,78,0.8);
    }
    .admintableprint td ,th{
        height: 40px;
        // border-bottom: 1px solid #e5e5e5;
        text-align: center;border-right: 1px solid rgba(60,69,78,0.8);
    }
    .activeIndex{
        // background: @activeTrBg;
        border:0 !important;
        color: #00A79D;
        td{
            border-top: 1px solid #00A79D;
            border-bottom: 1px solid #00A79D;
            &:last-child {
                border-right: 1px solid #00A79D;
            }
        }
    }
    .alreadyPrint{
        background: #fff;
    }
    .print-title{
        width: @width;
        margin: 0 auto;
    }
    .print-title,
    .print-content-tickettitle {
        .h(30px);
        background:rgba(249,251,251,1);
        padding-left: 10px;
        padding-top: 16px;
        margin-bottom: 16px;
    }
    .print-content-tickettitle {
        padding: 0;
        padding-top: 0px;
    }

    #print .el-input__inner {
        .h(20px);
    }
    .print-content {
        width: @width;
        margin:0 auto;
    }
    .font-weight {
        font-weight: 900;
        color: #000;
   
    }
    .trIndex{
        // background-color: #000;
        // color:#fff;
    }
    .a79{
        color:#00A79D;
    }
    .aff{
        color:#A0A0B4FF
    }
    .f00{
        color:#000000FF
    }
    .a4f{
        color:#4A4A4AFF
    }
    .f14px{
        font-size: 14px;
    }
      .table-head {
                height: 40px;
                line-height: 40px;
                color:#fff;
                font-size: 14px;
                display: flex;
                background-color: #39444e;
				justify-content: space-between;
                width: 1000px;
                padding: 0 10px;
                margin: 0 auto;
                .line1 {
                    display: inline-block; .h(12px); .w(1px); .bg(#fff); .m(0 5px);
                }
				.table-head-left {
					.span2,
					.span5 {
						margin-left: 8px;
					}
				}
			}

  .head-left {
                .fz(14px); .fw(600);
                .line {
                    display: inline-block; .h(12px); .w(1px); .bg(#39444e); .m(0 10px);
                }
                
                .span1,
                .span3 {
                    .c(#a0a0b4);
                }
                .span2,
                .span4,
                .span5 {
                    .c(#000);
                }
                .span5 {
                    .p(10px;);
                }
            }
            .table-head-right{
                .theme-color{
                    color:#00A79D;
                }
            }
          

</style>
