<template>
    <div class="tagsPage">
        <!-- <span class="circleBtn" v-if="type == 1 || type == 2">{{ concede }}</span> -->
        <el-row :gutter="16" class="row">
            <template v-if="type == 1">
                <el-col v-for="( item, index ) in btnList" :key="`btn-${index}`" :span="item.span">
                    <ButtonGroup v-if="index != 1"
                        size="small"
                        :class="{
                            'btnNoborder': isLottery? ( !item.disabled && item.isPrize ) || allRed: !item.disabled,
                            'isLottery': !item.disabled && isLottery
                        }">
                        <ivu-button :disabled="item.disabled" style="width: 48px;" type="error">{{ item.name }}</ivu-button>
                        <ivu-button :disabled="item.disabled" style="width: 48px;" type="default">{{ item.price }}</ivu-button>
                    </ButtonGroup>
                    <div v-else>&nbsp;</div>
                    <!-- <ivu-button v-else :disabled="item.disabled" size="small" style="width: 48px;" type="default">主  {{ item.price }}</ivu-button> -->
                </el-col>
            </template>
            <template v-if="type == 2">
                <el-col v-for="( item, index ) in btnList" :key="`btn-${index}`" :span="item.span">
                    <ButtonGroup v-if="index != 1"
                        size="small"
                        :class="{
                            'btnNoborder': isLottery? ( !item.disabled && item.isPrize ) || allRed: !item.disabled,
                            'isLottery': !item.disabled && isLottery
                        }">
                        <ivu-button :disabled="item.disabled" style="width: 48px;" type="error">{{ item.name }}</ivu-button>
                        <ivu-button :disabled="item.disabled" style="width: 48px;" type="default">{{ item.price }}</ivu-button>
                    </ButtonGroup>
                    <ivu-button v-else
                        :disabled="item.disabled"
                        size="small"
                        style="width: 48px;"
                        type="default"
                        :style="{color: item.price < 0? '#009688 !important': '#F57A23 !important'}">
                        主  {{ item.price }}
                    </ivu-button>
                </el-col>
            </template>
            <template v-if="type == 3">
                <el-col v-for="( item, index ) in row.content" :key="`btn-${index}`" :span="item.span">
                    <ButtonGroup v-if="index != 1"
                        size="small"
                        :class="{
                            'btnNoborder': item.redBorder || allRed,
                            'isLottery': item.gray
                        }">
                        <ivuButton style="width: 48px;" :disabled="isLottery? ( !item.redBorder && !item.gray ) :true" type="error">{{ item.desc }}</ivuButton>
                        <ivuButton style="width: 48px;" :disabled="isLottery? ( !item.redBorder && !item.gray ) :false" type="default">{{ item.odds }}</ivuButton>
                    </ButtonGroup>
                    <ivuButton v-else
                        style="width: 48px; color: #F57A23 !important;"
                        size="small"
                        type="default">
                        {{ item.extend }}
                    </ivuButton>
                </el-col>
            </template>
            <template v-if="type == 4">
                <el-col :span="12" v-for="( item, index ) in row.content" :key="`jcbf-${index}`" class="jclqSfc">
                    <ButtonGroup size="small"
                        :class="{
                            'btnNoborder': isLottery? item.desc != row.result_str: false,
                            'isLottery': ( item.desc == row.result_str && isLottery ) || allRed || !isLottery,
                            'noFirstRow': index > 2
                        }">
                        <ivuButton style="width: 69px;" type="error">{{ item.desc }}</ivuButton>
                        <ivuButton style="width: 60px;" type="default">{{ item.odds }}</ivuButton>
                    </ButtonGroup>
                </el-col>
            </template>
            <template v-if="type == 5">
                <el-col :span="8" v-for="( item, index ) in row.content" :key="`btn-${index}`">
                    <ButtonGroup size="small"
                        class="btnNoborder"
                        :class="{
                            'btnNoborder': isLottery? item.odds != row.result || allRed: true,
                            'isLottery': ( item.odds == row.result && isLottery ),
                            'noFirstRow': index > 2
                        }">
                        <ivuButton style="width: 72px;" type="error">{{ item.desc.slice(0,1)+' / '+item.desc.slice(1) }}</ivuButton>
                        <ivuButton style="width: 72px;" type="default">{{ item.odds }}</ivuButton>
                    </ButtonGroup>
                </el-col>
            </template>
        </el-row>
        <!-- <div style="border: 1px solid #444"></div> -->
    </div>
</template>

<script>
    import { ButtonGroup, Button as ivuButton } from 'iview';
    export default {
        props: [ 'row', 'concede', 'isLottery', 'type', 'allRed' ],
        data () {
            return {
                btnList: [],
            };
        },
        created () {
            this.row.content = JSON.parse( this.row.content );

            switch ( this.type ) {
                case 1:
                    this.isActiveBtn();
                    break;
                case 2:
                    this.initContent2();
                    break;
                case 3:
                    this.initContent3();
                    break;
                case 4:
                    this.initContent4();
                    break;
                default:
                    break;
            }

        },
        methods: {
            isActiveBtn () {
                this.btnList = [
                    { name: '客胜', price: this.row.src_odds_data.guest_win, disabled: true, isPrize: false, span: 9 },
                    { name: '', price: this.concede, disabled: false, isPrize: false, span: 6 },
                    { name: '主胜', price: this.row.src_odds_data.home_win, disabled: true, isPrize: false, span: 9 },
                ];
                let activeArr = [];
                this.row.content.map( ( item ) => activeArr.push( item.desc ) );
                if ( activeArr.length > 0 ) {
                    this.btnList[ 1 ].price = this.row.content.extend;
                }
                // 2019.12.4 数据结构调整去掉list
                this.row.content.map( ( item ) => {
                    if ( activeArr.includes( 'guest_win' ) ) {
                        this.btnList[ 0 ].disabled = false;
                        if ( item.desc == 'guest_win' ) {
                            this.btnList[ 0 ].price = item.odds;
                        }
                    }
                    if ( activeArr.includes( 'home_win' ) ) {
                        this.btnList[ 2 ].disabled = false;
                        if ( item.desc == 'home_win' ) {
                            this.btnList[ 2 ].price = item.odds;
                        }
                    }
                } );
                // if ( activeArr.includes( 'guest_win' ) ) {
                //     this.btnList[ 0 ].disabled = false;
                //     this.row.content.list.map( ( item ) => {
                //         if ( item.desc == 'guest_win' ) {
                //             this.btnList[ 0 ].price = item.odds;
                //         }
                //     } );
                // }
                // if ( activeArr.includes( 'home_win' ) ) {
                //     this.btnList[ 2 ].disabled = false;
                //     this.row.content.list.map( ( item ) => {
                //         if ( item.desc == 'home_win' ) {
                //             this.btnList[ 2 ].price = item.odds;
                //         }
                //     } );
                // }
                if ( this.isLottery ) {
                    if ( this.row.result_str == 'guest_win' ) {
                        this.btnList[ 0 ].isPrize = true;
                    }
                    if ( this.row.result_str == 'home_win' ) {
                        this.btnList[ 2 ].isPrize = true;
                    }
                }
            },
            initContent2 () {
                this.btnList = [
                    { name: '客胜', price: this.row.src_odds_data.guest_win, disabled: true, isPrize: false, span: 9 },
                    { name: '', price: this.row.src_odds_data.let_points, disabled: false, isPrize: false, span: 6 },
                    { name: '主胜', price: this.row.src_odds_data.home_win, disabled: true, isPrize: false, span: 9 },
                ];
                let activeArr = [];
                this.row.content.list.map( ( item ) => activeArr.push( item.desc ) );
                if ( activeArr.length > 0 ) {
                    this.btnList[ 1 ].price = this.row.content.extend;
                }
                if ( activeArr.includes( 'guest_win' ) ) {
                    this.btnList[ 0 ].disabled = false;
                    this.row.content.list.map( ( item ) => {
                        if ( item.desc == 'guest_win' ) {
                            this.btnList[ 0 ].price = item.odds;
                        }
                    } );
                }
                if ( activeArr.includes( 'home_win' ) ) {
                    this.btnList[ 2 ].disabled = false;
                    this.row.content.list.map( ( item ) => {
                        if ( item.desc == 'home_win' ) {
                            this.btnList[ 2 ].price = item.odds;
                        }
                    } );
                }
                if ( this.isLottery ) {
                    if ( this.row.result_str == 'guest_win' ) {
                        this.btnList[ 0 ].isPrize = true;
                    }
                    if ( this.row.result_str == 'home_win' ) {
                        this.btnList[ 2 ].isPrize = true;
                    }
                }
            },
            initContent4 () {
                this.row.content.map( ( item ) => {
                    if ( item.desc.search( 'lose-' ) != -1 ) {
                        item.desc = item.desc.replace( /\lose-/g, '客胜' );
                    } else {
                        item.desc = item.desc.replace( /\win-/g, '主胜' );
                    }
                } );
                this.row.result_str = this.row.result_str.replace( /\lose-/g, '客胜' );
                this.row.result_str = this.row.result_str.replace( /\win-/g, '主胜' );
            },
            initContent3 () {
                let newContent = [
                    { desc: '大分', odds: this.row.src_odds_data.big, redBorder: false, gray: false, span: 9 },
                    { extend: this.row.content.extend, span: 6 },
                    { desc: '小分', odds: this.row.src_odds_data.little, redBorder: false, gray: false, span: 9 },
                ];
                newContent.map( ( item, index ) => {
                    if ( index == 0 ) {
                        this.row.content.list.map( ( item2, index2 ) => {
                            if ( item2.desc == 'big' ) {
                                item.odds = item2.odds;
                                if ( this.isLottery && this.row.result_str != 'big' ) {
                                    item.gray = true;
                                } else {
                                    item.redBorder = true;
                                }
                            }
                        } );
                    } else if ( index == 2 ) {
                        this.row.content.list.map( ( item2, index2 ) => {
                            if ( item2.desc == 'little' ) {
                                item.odds = item2.odds;
                                if ( this.isLottery && this.row.result_str != 'little' ) {
                                    item.gray = true;
                                } else {
                                    item.redBorder = true;
                                }
                            }
                        } );
                    }
                } );
                this.row.content = newContent;
            },
        },
        components: {
            ButtonGroup,
            ivuButton,
        },
    };
</script>

<style lang="less" scoped>
    .tagsPage {
        padding: 10px 0;
        display: flex;
        .row {
            flex: 1;
        }
        // .circleBtn{
        //     display: inline-flex;
        //     justify-content: center;
        //     align-items: center;
        //     width: 22px;
        //     height: 22px;
        //     background: #eee;
        //     border-radius: 50%;
        //     color: #ed4014;
        //     margin-right: 16px;
        // }
        .noFirstRow {
            margin-top: 8px;
        }
        .el-col-12{
            width: auto;
            padding: 0 !important;
            padding-left: 8px !important;
            
        }
        .el-col-9{
            width: auto;
            padding-right:5px!important;
        }
        .el-col-6{
            width: 14%;
        }
    }
</style>