<template>
    <div class="hhjcTable">
        <ivuTable :row-class-name="rowClassName" :columns="columns" :data="data" :show-header="false"></ivuTable>
    </div>
</template>

<script>
    import { Table as ivuTable } from 'iview';
    export default {
        props: [ 'row', 'concede', 'isLottery', 'type' ],
        data () {
            return {
                columns: [
                    {
                        title: 'Name',
                        align: 'center',
                        // eslint-disable-next-line complexity
                        render: ( h, params ) => {
                            let type = params.row.odds_type;
                            let str = '';
                            if ( this.row.match_info.status >= 100 && this.isLottery ) {
                                str = '延期';
                            } else {
                                if ( this.isLottery ) {
                                    let winLostDraw = [ 1, 2 ];
                                    if ( winLostDraw.includes( type ) ) {
                                        if ( params.row.result_str == 'win' ) {
                                            str = '胜';
                                        } else if ( params.row.result_str == 'lose' ) {
                                            str = '负';
                                        } else if ( params.row.result_str == 'draw' ) {
                                            str = '平';
                                        }
                                    } else if ( type == 3 && params.row.result_str != '' ) {
                                        str = params.row.result_str + '球';
                                    } else if ( type == 4 ) {
                                        str = params.row.result_str;
                                    } else if ( type == 5 && params.row.result_str != '' ) {
                                        str = params.row.result_str.slice( 0, 1 ) + ' / ' + params.row.result_str.slice( 1 );
                                    }else if ( type == 6 && params.row.result_str != '' ) {
                                        if ( params.row.result_str == 'up_single' ) {
                                            str = '上单';
                                        } else if ( params.row.result_str == 'up_double' ) {
                                            str = '上双';
                                        } else if ( params.row.result_str == 'down_single' ) {
                                            str = '下单';
                                        } else if ( params.row.result_str == 'down_double' ) {
                                            str = '下双';
                                        }
                                    }
                                }
                            }
                            return h( 'div', {
                                style: {
                                    color: '#BE0B00', fontWeight: '600',
                                },
                            }, str );
                        },
                    },
                ],
                data: null,
            };
        },
        created () {
            let arr = [];
            let keyArr = [ 'odds_base', 'odds_goals', 'odds_half_whole', 'odds_handicap', 'odds_point' , 'win_lose' ];
            for ( let key in this.row ) {
                if ( keyArr.includes( key ) ) {
                    arr.push( this.row[ key ] );
                }
            }
            this.data = arr;
        },
        methods: {
            rowClassName ( row, index ) {
                if ( index === this.data.length - 1 ) {
                    return 'last-child-row';
                }
                return '';
            },
        },
        components: {
            ivuTable,
        },
    };
</script>