/*
* @Author: hcx
* @Date:   2019-07-10 12:36:14
* @Last Modified by:   hcx
* @Last Modified time: 2019-07-10 12:48:16
*/

'use strict';
export default {

    /**
     * [timeRange 判断当前时间是否是在开始和结束区间]
     * @param  {[type]} beginTime [description]
     * @param  {[type]} endTime   [description]
     * @return {[type]}           [Boolean类型]
     */
    timeRange ( beginTime, endTime ) {
        var strb = beginTime.split( ':' );
        if ( strb.length != 2 ) {
            return false;
        }

        var stre = endTime.split( ':' );
        if ( stre.length != 2 ) {
            return false;
        }

        var b = new Date();
        var e = new Date();
        var n = new Date();

        b.setHours( strb[ 0 ] );
        b.setMinutes( strb[ 1 ] );
        e.setHours( stre[ 0 ] );
        e.setMinutes( stre[ 1 ] );

        if ( n.getTime() - b.getTime() > 0 && n.getTime() - e.getTime() < 0 ) {
            // alert ("当前时间是：" + n.getHours () + ":" + n.getMinutes () + "，在该时间范围内！");
            return true;
        } else {
            // alert ("当前时间是：" + n.getHours () + ":" + n.getMinutes () + "，不在该时间范围内！");
            return false;
        }
    },

    /**
     * [isDate 判断数据是不是时间对象]
     * @param  {[type]}  value [description]
     * @return {Boolean}       [Boolean类型]
     */
    isDate ( value ) {
        return Object.prototype.toString.call( value ) === '[object Date]';
    },

    /**
     * [dateFormater 格式化时间]
     * @param  {[type]} formater [description]
     * @param  {[type]} t        [description]
     * @return {[type]}          [description]
     */
    dateFormater ( formater, t ) {
        let date = t ? new Date( t ) : new Date(),
            Y = date.getFullYear() + '',
            M = date.getMonth() + 1,
            D = date.getDate(),
            H = date.getHours(),
            m = date.getMinutes(),
            s = date.getSeconds();
        return formater.replace( /YYYY|yyyy/g, Y )
            .replace( /YY|yy/g, Y.substr( 2, 2 ) )
            .replace( /MM/g, ( M < 10 ? '0' : '' ) + M )
            .replace( /DD/g, ( D < 10 ? '0' : '' ) + D )
            .replace( /HH|hh/g, ( H < 10 ? '0' : '' ) + H )
            .replace( /mm/g, ( m < 10 ? '0' : '' ) + m )
            .replace( /ss/g, ( s < 10 ? '0' : '' ) + s );
        // dateFormater('YYYY-MM-DD HH:mm', t) ==> 2019-06-26 18:30
        // dateFormater('YYYYMMDDHHmm', t) ==> 201906261830
    },

    /**
     * [dateStrForma description]
     * @param  {[type]} str  [description]
     * @param  {[type]} from [description]
     * @param  {[type]} to   [description]
     * @return {[type]}      [description]
     */
    dateStrForma ( str, from, to ) {
        // '20190626' 'YYYYMMDD' 'YYYY年MM月DD日'
        str += '';
        let Y = '';
        if ( ~( Y = from.indexOf( 'YYYY' ) ) ) {
            Y = str.substr( Y, 4 );
            to = to.replace( /YYYY|yyyy/g, Y );
        } else if ( ~( Y = from.indexOf( 'YY' ) ) ) {
            Y = str.substr( Y, 2 );
            to = to.replace( /YY|yy/g, Y );
        }

        let k, i;
        [ 'M', 'D', 'H', 'h', 'm', 's' ].forEach( ( s ) => {
            i = from.indexOf( s + s );
            k = ~i ? str.substr( i, 2 ) : '';
            to = to.replace( s + s, k );
        } );
        return to;
        // dateStrForma('20190626', 'YYYYMMDD', 'YYYY年MM月DD日') ==> 2019年06月26日
        // dateStrForma('121220190626', '----YYYYMMDD', 'YYYY年MM月DD日') ==> 2019年06月26日
        // dateStrForma('2019年06月26日', 'YYYY年MM月DD日', 'YYYYMMDD') ==> 20190626

        // 一般的也可以使用正则来实现
        // '2019年06月26日'.replace(/(\d{4})年(\d{2})月(\d{2})日/, '$1-$2-$3') ==> 2019-06-26
    },

    /**
     * [countDown 转换成是时分秒]
     * @param  {[type]}  intDiff   [传入一个总秒数]
     * @return {[type]}            [时分秒]
     */
    countDown ( intDiff ) {
        let day = 0,
            hour = 0,
            minute = 0,
            second = 0;// 时间默认值
        if ( intDiff > 0 ) {
            // day = Math.floor(intDiff / (60 * 60 * 24));
            hour = Math.floor( intDiff / ( 60 * 60 ) ) - day * 24;
            minute = Math.floor( intDiff / 60 ) - day * 24 * 60 - hour * 60;
            second = Math.floor( intDiff ) - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60;
        }
        if ( hour <= 9 ) {hour = '0' + hour;}
        if ( minute <= 9 ) {minute = '0' + minute;}
        if ( second <= 9 ) {second = '0' + second;}
        intDiff--;
        // return day + '天' + hour + ':' + minute + ':' + second;
        // return hour + ':' + minute + ':' + second;
        // return `${hour} 时 ${minute} 分 ${second} 秒`;
        let obj = {
            hour: hour,
            minute: minute,
            second: second,
            num: intDiff,
        };
        return obj;
    },
    // 获取出生日期
    getBirthdayFromIdCard ( value ) {
        let iSum = 0;

        /* 省份验证 */
        let aCity = {11: '北京', 12: '天津', 13: '河北', 14: '山西', 15: '内蒙古', 21: '辽宁', 22: '吉林', 23: '黑龙江', 31: '上海', 32: '江苏', 33: '浙江', 34: '安徽', 35: '福建', 36: '江西', 37: '山东', 41: '河南', 42: '湖北', 43: '湖南', 44: '广东', 45: '广西', 46: '海南', 50: '重庆', 51: '四川', 52: '贵州', 53: '云南', 54: '西藏', 61: '陕西', 62: '甘肃', 63: '青海', 64: '宁夏', 65: '新疆', 71: '台湾', 81: '香港', 82: '澳门', 91: '国外'};
        let v = String( value ).replace( /x$/i, 'a' );

        /* 出生日期验证 */
        let b = value.substr( 6, 4 ) + '-' + Number( value.substr( 10, 2 ) ) + '-' + Number( value.substr( 12, 2 ) );
        let birthDay = value.substr( 6, 4 ) + '-' + value.substr( 10, 2 ) + '-' + value.substr( 12, 2 );
        let bir = new Date( b.replace( /-/g, '/' ) );

        /* 校验位错误验证 */
        let code = value.split( '' );
        // 加权因子
        let factor = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ];
        // 校验位
        let parity = [ 1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2 ];
        let sum = 0, ai = 0, wi = 0;
        for ( let i = 0; i < 17; i++ ) {
            ai = code[ i ];wi = factor[ i ];sum += ai * wi;
        }
        let last = parity[ sum % 11 ];
        if ( !value ) {
            return '';
        } else if ( !/^\d{17}(\d|x)$/i.test( value ) ) {
            return '';
        } else if ( aCity[ parseInt( v.substr( 0, 2 ) ) ] == null ) {
            return '';
        } else if ( b != bir.getFullYear() + '-' + ( bir.getMonth() + 1 ) + '-' + bir.getDate() ) {
            return '';
        } else if ( parity[ sum % 11 ] != code[ 17 ].toUpperCase() ) {
            return '';
        } else {
            let pri = aCity[ parseInt( value.substr( 0, 2 ) ) ];
            if ( pri != '北京' && pri != '天津' && pri != '重庆' && pri != '上海' && pri != '广西' && pri != '内蒙古' && pri != '宁夏' && pri != '新疆' && pri != '西藏' ) {
                pri += '省';
            } else if ( pri == '北京' || pri == '天津' || pri == '上海' || pri == '重庆' ) {
                pri += '市';
            } else if ( pri == '西藏' || pri == '内蒙古' ) {
                pri += '自治区';
            } else if ( pri == '新疆' ) {
                pri += '维吾尔自治区';
            } else if ( pri == '广西' ) {
                pri += '壮族自治区';
            } else if ( pri == '宁夏' ) {
                pri += '回族自治区';
            }
            let obj = {
                sex: value.substr( 16, 1 ) % 2 ? '男' : '女',
                birthday: birthDay,
                province: pri,
            };
            return obj;
        }
    },

    /**
     * [getWeek                 获取今天是周几]
     * @param  {[type]}  date   [ 日期为输入日期，格式为 2019-07-10 ]
     * @return {[string]}       [返回星期*]
     */
    getWeek ( date ) {
        let arys1 = date.split( '-' );
        let ssdate = new Date( arys1[ 0 ], parseInt( arys1[ 1 ] - 1 ), arys1[ 2 ] );
        let week = String( ssdate.getDay() ).replace( '0', '日' )
            .replace( '1', '一' )
            .replace( '2', '二' )
            .replace( '3', '三' )
            .replace( '4', '四' )
            .replace( '5', '五' )
            .replace( '6', '六' );
        return `周${week}`;
    },
};
