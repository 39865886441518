<template>
    <div class="tagsPage">
        <el-row :gutter="10" class="row" justify="center">
            <template>
                <el-col :span="8">
                    <button-group size="small"
                        :class="{
                            'btnNoborder': isLottery&&row.result_str!='延期'? ( !btnList[0].disabled && btnList[0].isPrize ): !btnList[0].disabled,
                            'isLottery': !btnList[0].disabled && isLottery
                        }">
                        <ivu-button :disabled="btnList[0].disabled" style="width: 48px;" type="error">{{ btnList[0].name }}</ivu-button>
                        <ivu-button v-if="isLottery" :disabled="btnList[0].disabled" style="width: 48px;" type="default">    
                           {{ ( !btnList[0].disabled && btnList[0].isPrize )? btnList[0].price: '&nbsp;' }}
                            </ivu-button>
                        <ivu-button v-else :disabled="btnList[0].disabled" style="width: 48px;" type="default">{{ btnList[0].price }}</ivu-button>
                    </button-group>
                </el-col>
                <el-col :span="4">
                    <div class="concede">
                        <span class="circleBtn"  :class="{ 
                        'noBg': concede == 0 ,
                        'red':concede > 0 
                        }">{{ concede != '0'? concede: '' }}</span>
                        {{concede_desc}}
                    </div>
                </el-col>
                <el-col :span="8">
                    <button-group size="small"
                        :class="{
                            'btnNoborder': isLottery&&row.result_str!='延期'? ( !btnList[1].disabled && btnList[1].isPrize ): !btnList[1].disabled,
                            'isLottery': !btnList[1].disabled && isLottery
                        }">
                        <ivu-button :disabled="btnList[1].disabled" style="width: 48px;" type="error">{{ btnList[1].name }}</ivu-button>
                        <ivu-button v-if="isLottery" :disabled="btnList[1].disabled" style="width: 48px;" type="default">    
                           {{ ( !btnList[1].disabled && btnList[1].isPrize )? btnList[1].price: '&nbsp;' }}
                            </ivu-button>
                        <ivu-button v-else :disabled="btnList[1].disabled" style="width: 48px;" type="default">{{ btnList[1].price }}</ivu-button>
                    </button-group>
                </el-col>
            </template>
        </el-row>
    </div>
</template>

<script>
    import { ButtonGroup, Button as ivuButton } from 'iview';
    export default {
        props: [ 'row', 'concede', 'concede_desc' ,'isLottery', 'type', 'playStatus' ],
        data () {
            return {
                btnList: [],
            };
        },
        created () {
            this.row.content = JSON.parse( this.row.content );
            switch ( this.type ) {
                case 1:
                    this.isActiveBtn();
                    break;
                case 2:
                    this.isActiveBtn();
                    break;
                default:
                    break;
            }
        },
        methods: {
            // eslint-disable-next-line complexity
            isActiveBtn () {
                this.btnList = [
                    { name: '主胜', price: this.row.src_odds_data.win, disabled: true, isPrize: false },
                    // { name: '平', price: this.row.src_odds_data.draw, disabled: true, isPrize: false },
                    { name: '客胜', price: this.row.src_odds_data.lose, disabled: true, isPrize: false },
                ];
                let activeArr = [];
                this.row.content.map( ( item ) => activeArr.push( item.desc ) );
                if ( activeArr.includes( 'win' ) ) {
                    this.btnList[ 0 ].disabled = false;
                }
                if ( activeArr.includes( 'lose' ) ) {
                    this.btnList[ 1 ].disabled = false;
                }

                if ( this.isLottery ) {
                    if ( this.row.result_str == 'win' ) {
                        this.btnList[ 0 ].isPrize = true;
                        if ( this.row.result_sp === 0 ) {
                            this.btnList[ 0 ].price = this.row.src_odds_data.win;
                        } else {
                            this.btnList[ 0 ].price = this.row.result.win;
                        }
                    }
                    if ( this.row.result_str == 'lose' ) {
                        this.btnList[ 1 ].isPrize = true;
                        if ( this.row.result_sp === 0 ) {
                            this.btnList[ 1 ].price = this.row.src_odds_data.lose;
                        } else {
                            this.btnList[ 1 ].price = this.row.result.lose;
                        }
                    }
                } else if ( this.playStatus >= 100 ) {// 延期判断
                    this.btnList.map( ( item ) => {
                        if ( !item.disabled ) {
                            item.isPrize = true;
                            item.price = '1.00';
                        }
                    } );
                }
            },
        },
        components: {
            ButtonGroup,
            ivuButton,
        },
    };
</script>

<style lang="less" scoped>
    .tagsPage {
        padding: 10px 0;
        display: flex;
        .row {
            flex: 1;
            display: flex;
            justify-content: center;
        }
        .concede{
            width:70px;
            height:26px;
            background: #F9FBFB;
            border:1px solid #F6F6F6;
            margin:0 -15px 0 -15px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
        .circleBtn{
            color: #00a79d;
            margin-right: 10px;
            font-weight: 600;
        }
        .red {
            color: (#BE0B00);
        }
        .noBg {
            background: #fff;
        }
        .noFirstRow {
            margin-top: 8px;
        }
        ::v-deep .el-col-8{
            width: 32%;
            padding: 0 !important;
        }
    }
</style>
