<template>
    <span class="item-btn">
        <ivu-button
            :type="type"
            shape="circle"
            size="small"
            class="circle_btn"
            v-for="( item, index ) in list"
            :ghost="ghost && !select.includes( item )"
            :key="`btn-${ index }`">
            {{ item }}
        </ivu-button>
    </span>
</template>
<script scoped>
    import { Button as ivuButton } from 'iview';
    export default {
        props: {
            type: {
                default: 'primary',
            },
            list: {
                required: true,
                type: Array,
            },
            ghost: {
                default: false,
            },
            select: {
                required: true,
            },
        },
        components: {
            ivuButton,
        },
    };
</script>
<style lang="less" scoped>
    .ivu-btn-success, .ivu-btn-primary {
        display: inline-flex; justify-content: center; align-items: center;
        width: 26px;
        height: 26px;
        text-align: center;
        margin-right: 4px;
        // border-width: 2px;
        font-weight: 600;
    }
    .item-btn {
        .ivu-btn {
            margin-bottom: 0px;
            cursor: auto;
            margin-bottom: 10px; 
        }
        ::v-deep .ivu-btn-success {
            background: #BE0B00;
            border-color: #FFCDCD;
        }
        ::v-deep .ivu-btn-primary {
            background: #002BB9;
            border-color: #2d8cf0;;
        }
        ::v-deep .ivu-btn-success.ivu-btn-ghost {
            color: #BE0B00;
        }
        ::v-deep .ivu-btn-primary.ivu-btn-ghost {
            color: #002BB9;
        }
        ::v-deep .ivu-btn-ghost {
            background: 0 0;
        }
    }
</style>