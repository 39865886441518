<template>
    <div class="table-page">
        <!-- lottery_type：5，play_type：1竞篮胜负play_type：2竞篮让球胜负play_type：3竞篮大小分play_type：4竞篮胜分差play_type：6竞篮混合过关 -->
        <ivuTable border :columns="table.columns" :data="data.plan_detail"></ivuTable>
    </div>
</template>

<script>
    import { Table as ivuTable } from 'iview';
    import tableTag from './tag';
    import hhjcTable from './hhjcTable';
    import common from '@/utils/common';
    import date from '@/utils/date';
    import resultStr from './resule';
    export default {
        props: {
            data: {
                required: true,
            },
        },
        data () {
            let type = this.data.order_info.play_type;
            let tableKey = '';
            // eslint-disable-next-line default-case
            switch ( type ) {
                case 1:
                    tableKey = 'odds_base';
                    break;
                case 2:
                    tableKey = 'odds_concede';
                    break;
                case 3:
                    tableKey = 'odds_big_little';
                    break;
                case 4:
                    tableKey = 'odds_difference';
                    break;
                case 5:
                    tableKey = 'odds_half_whole';
                    break;
                case 6:
                    tableKey = 'plan_detail';
                    break;
            }
            return {
                table: {
                    columns: [
                        {
                            title: '序号',
                            align: 'center',
                            className: 'touzhuxiang',
                            width: 100,
                            render: ( h, params ) => {
                                let num = common.toZhDigit( params.row.match_info.match_turn.substring( 0, 1 ) );
                                let str = `周${num == '七' ? '日' : num}`;
                                return h( 'div', `${str}${params.row.match_info.match_turn.substring( 1 )}` );
                            },
                        },
                        {
                            title: '时间',
                            align: 'center',
                            className: 'touzhuxiang',
                            width: 105,
                            render: ( h, params ) => h( 'div', date.dateFormater( 'MM-DD hh:mm', params.row.match_info.start_time ) ),
                            // return h( 'div', params.row.match_info.start_time );
                        },
                        {
                            title: '对阵',
                            align: 'center',
                            className: 'touzhuxiang',
                            width: 250,
                            render: ( h, params ) => {
                                // if ( type != 6 ) {
                                let str = '';
                                if ( this.data.order_info.prize_cash != 0 ) {
                                    str = params.row.match_info.match_status != 5
                                        ? `${params.row.match_info.kedui_score}:${params.row.match_info.zhudui_score}`
                                        : '延期' ;
                                } else {
                                    str = 'VS';
                                }
                                return h( 'div', [
                                    h( 'span', {
                                        style: {
                                            width: '75px', display: 'inline-block', textAlign: 'right',
                                        },
                                    }, params.row.match_info.kedui_name ),
                                    h( 'span', {
                                        style: {
                                            color: this.data.order_info.prize_cash != 0 ? '#BE0B00' : '#999',
                                            margin: '0 3px', width: '45px', display: 'inline-block',
                                            fontWeight: '600',
                                        },
                                    }, str ),
                                    h( 'span', {
                                        style: {
                                            width: '75px', display: 'inline-block', textAlign: 'left',
                                        },
                                    }, params.row.match_info.zhudui_name ),
                                ] );
                                // } else {
                                //     return h( resultStr, {
                                //         props: {
                                //             tableType: 'duizhen',
                                //             row: params.row,
                                //             concede: params.row.match_info.let_points,
                                //             isLottery: this.data.order_info.prize_cash != 0,
                                //             type: this.data.order_info.play_type,
                                //         }
                                //     } )
                                // }

                            },
                        },
                        {
                            title: '投注项',
                            align: 'center',
                            className: 'touzhuxiang',
                            minWidth: 453,
                            render: ( h, params ) => {
                                let jcArr = [ 1, 2, 3, 4, 5 ];
                                if ( jcArr.includes( type ) ) {
                                    return h( tableTag, {
                                        props: {
                                            row: params.row[ tableKey ],
                                            concede: params.row.match_info.let_points,
                                            isLottery: this.data.order_info.prize_cash != 0,
                                            type: this.data.order_info.play_type,
                                            allRed: params.row.match_info.match_status == 5,
                                        },
                                    } );
                                } else {
                                    return h( hhjcTable, {
                                        props: {
                                            row: params.row,
                                            concede: params.row.match_info.let_points,
                                            isLottery: this.data.order_info.prize_cash != 0,
                                            type: this.data.order_info.play_type,
                                            allRed: params.row.match_info.match_status == 5,
                                        },
                                    } );
                                }
                            },
                        },
                        {
                            title: '彩果',
                            align: 'center',
                            className: 'spzhi',
                            minWidth: 90,
                            // eslint-disable-next-line complexity
                            render: ( h, params ) => {
                                if ( type != 6 ) {
                                    let str = '';
                                    let resultStr = params.row[ tableKey ].result_str;
                                    if ( ( type == 1 || type == 2 ) && resultStr != '' ) {
                                        if ( resultStr == 'guest_win' ) {
                                            str = '客胜';
                                        } else if ( resultStr == 'home_win' ) {
                                            str = '主胜';
                                        }
                                    } else if ( type == 3 && resultStr != '' ) {
                                        if ( resultStr == 'little' ) {
                                            str = '小分';
                                        } else {
                                            str = '大分';
                                        }
                                    } else if ( type == 4 && resultStr != '' ) {
                                        if ( resultStr.search( 'lose-' ) != -1 ) {
                                            str = resultStr.replace( /\lose-/g, '客胜' );
                                        } else {
                                            str = resultStr.replace( /\win-/g, '主胜' );
                                        }
                                    } else if ( type == 5 && resultStr != '' ) {
                                        str = resultStr.slice( 0, 1 ) + ' / ' + resultStr.slice( 1 );
                                    } else {
                                        str = '';
                                    }
                                    return h( 'div', {
                                        style: {
                                            color: '#BE0B00',
                                        },
                                    }, str );
                                } else {
                                    return h( resultStr, {
                                        props: {
                                            tableType: 'result',
                                            row: params.row,
                                            concede: params.row.match_info.let_points,
                                            isLottery: this.data.order_info.prize_cash != 0,
                                            type: this.data.order_info.play_type,
                                        },
                                    } );
                                }
                            },
                        },
                    ],
                },
            };
        },
        components: {
            ivuTable,
            tableTag,
        },
    };
</script>