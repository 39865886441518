/* eslint-disable complexity */
/* eslint-disable complexity */
/* eslint-disable complexity */
/* eslint-disable complexity */
<template>
    <div class="table-page">
          <!-- filter_plan 1：非导入方案；2： 导入方案 -->
        <div class="filter_plan" v-if="filter_plan == 2">
            <img :src="importImg"/>
            <p>导入方案无法查看选单详情</p>
        </div>
        <template v-if="filter_plan == 1">
            <ivuTable border :columns="table.columns" :data="data.plan_detail"></ivuTable>
        </template>
    </div>
</template>

<script>
    import { Table as ivuTable } from 'iview';
    import tableTag from './tag';
    import resultStr from './resule';
    import importImg from '@/assets/img/order/import.png';
    export default {
        props: {
            data: {
                required: true,
            },
        },
        data () {
            let type = this.data.order_info.play_type;
            let tableKey = '';
            // eslint-disable-next-line default-case
            switch ( type ) {
                case 1:
                    tableKey = '';
                    break;
                case 2:
                    tableKey = 'odds_handicap';
                    break;
                case 3:
                    tableKey = 'odds_goals';
                    break;
                case 4:
                    tableKey = 'odds_point';
                    break;
                case 5:
                    tableKey = 'odds_half_whole';
                    break;
                case 6:
                    tableKey = 'single_double';
                    break;
            }
            return {
                filter_plan: this.data.order_info.filter_plan,
                importImg,
                table: {
                    columns: [
                        {
                            title: '序号',
                            minWidth: 60,
                            className: 'touzhuxiang',
                            align: 'center',
                            render: ( h, params ) => h( 'div', params.row.match_info.match_turn ),
                        },
                        {
                            title: '时间',
                            minWidth: 120,
                            className: 'touzhuxiang',
                            align: 'center',
                            render: ( h, params ) => h( 'div', params.row.match_info.start_time.substring( 5 ) ),
                        },
                        {
                            title: '对阵',
                            minWidth: 250,
                            className: 'touzhuxiang',
                            align: 'center',
                            render: ( h, params ) => {
                                let str = '';
                                if ( params.row[ tableKey ].is_passed != 0 ) {
                                    str = `${params.row.match_info.zhujin}:${params.row.match_info.kejin}`;
                                } else if ( params.row.match_info.status >= 100 ) {
                                    str = '延期';
                                } else {
                                    str = 'VS';
                                }
                                return h( 'div', [
                                    h( 'span', {
                                        style: {
                                            width: '75px', display: 'inline-block', textAlign: 'right',
                                        },
                                    }, params.row.match_info.zhudui_name ),
                                    h( 'span', {
                                        style: {
                                            color: params.row[ tableKey ].is_passed != 0 ? '#BE0B00' : '#A0A0B4',
                                            margin: '0 3px', width: '35px', fontWeight: '600', display: 'inline-block',
                                        },
                                    }, str ),
                                    h( 'span', {
                                        style: {
                                            width: '75px', display: 'inline-block', textAlign: 'left',
                                        },
                                    }, params.row.match_info.kedui_name ),
                                ] );
                            },
                        },
                        {
                            title: '投注项',
                            align: 'center',
                            className: 'touzhuxiang',
                            minWidth: 370,
                            render: ( h, params ) => h( tableTag, {
                                props: {
                                    row: params.row[ tableKey ],
                                    concede: params.row.match_info.concede,
                                    isLottery: params.row[ tableKey ].is_passed != 0,
                                    type: this.data.order_info.play_type,
                                    playStatus: params.row.match_info.status,
                                },
                            } ),
                        },
                        {
                            title: '彩果',
                            align: 'center',
                            className: 'touzhuxiang',
                            minWidth: 70,
                            render: ( h, params ) => {
                                // 之前是6 现在改成了7 有可能会出现问题
                                if ( type != 7 ) {
                                    let str = '';
                                    let winLostDraw = [ 1, 2 ];
                                    if ( params.row.match_info.status == '50' ) {
                                        if ( winLostDraw.includes( type ) ) {
                                            if ( params.row[ tableKey ].result_str == 'win' ) {
                                                str = '胜';
                                            } else if ( params.row[ tableKey ].result_str == 'lose' ) {
                                                str = '负';
                                            } else if ( params.row[ tableKey ].result_str == 'draw' ) {
                                                str = '平';
                                            }
                                        } else if ( type == 3 && params.row[ tableKey ].result_str != '' ) {
                                            str = params.row[ tableKey ].result_str + '球';
                                        } else if ( type == 4) {
                                            str = params.row[ tableKey ].result_str;
                                        } else if ( type == 5 && params.row[ tableKey ].result_str != '' ) {
                                            str = params.row[ tableKey ].result_str.slice( 0, 1 ) + ' / ' + params.row[ tableKey ].result_str.slice( 1 );
                                        }else if(type == 6 && params.row[ tableKey ].result_str != '' ){
                                            str = params.row[ tableKey ].result_str == 'up_single'?'上单':params.row[ tableKey ].result_str == 'up_double'?'上双':params.row[ tableKey ].result_str == 'down_single'?'下单':'下双'
                                        }
                                    }
                                    if ( params.row.match_info.status >= 100 ) {
                                        str = '延期';
                                    }
                                    return h( 'div', {
                                        style: {
                                            color: '#BE0B00', fontWeight: '600',
                                        },
                                    }, str );
                                } else {
                                    return h( resultStr, {
                                        props: {
                                            row: params.row,
                                            concede: params.row.match_info.concede,
                                            isLottery: params.row[ tableKey ].is_passed != 0,
                                            type: this.data.order_info.play_type,
                                        },
                                    } );
                                }
                            },
                        },
                        {
                            title: 'SP值',
                            align: 'center',
                            className: 'spzhi',
                            minWidth: 80,
                            render: ( h, params ) => {
                                if ( params.row.match_info.status >= 100 ) {
                                    return h( 'div', {
                                        style: {
                                            color: '#BE0B00',
                                        },
                                    }, '1.00' );
                                } else if ( params.row[ tableKey ].is_passed === 0 ) {
                                    return h( 'div', '' );
                                } else {
                                    let str = '';
                                    if ( tableKey === 'odds_handicap' ) {
                                        str = params.row[ tableKey ].result[ params.row[ tableKey ].result_str ];
                                    } else {
                                        str = params.row[ tableKey ].result;
                                    }
                                    return h( 'div', {
                                        style: {
                                            color: '#BE0B00', fontWeight: '600',
                                        },
                                    }, str == 0 ? '' : str );
                                }
                            },
                        },
                    ],
                },
            };
        },
        components: {
            tableTag, ivuTable, resultStr,
        },
    };
</script>

<style lang="less" scoped>
    .filter_plan{
        background: #F9FBFB;
        padding-bottom: 20%;
        color:#39444e;
        text-align: center;
        padding-top: 10%;
        img{
            display: inline-block;
        }
        p{
            margin-top: 10px;
        }
    }
    ::v-deep .ivu-table-cell{
        padding-left: 12px;
        padding-right: 12px;
    }
</style>
