var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tagsPage"},[(_vm.type == 2)?_c('span',{staticClass:"circleBtnf",class:_vm.concede > 0 ? 'circleBtn red' : 'circleBtn'},[_vm._v(_vm._s(_vm.concede))]):_c('span',{staticClass:"circleBtn"}),_c('el-row',{staticClass:"row",attrs:{"gutter":16}},[(_vm.type == 1 || _vm.type == 2)?_vm._l((_vm.btnList),function(item,index){return _c('el-col',{key:("btn-" + index),attrs:{"span":8}},[_c('ButtonGroup',{class:{ 'btnNoborder': _vm.isLottery? ( !item.disabled && item.isPrize ): !item.disabled, 'isLottery': !item.disabled && _vm.isLottery },attrs:{"size":"small"}},[_c('ivuButton',{staticStyle:{"width":"48px"},attrs:{"disabled":item.disabled,"type":"error"}},[_vm._v(_vm._s(item.name))]),_c('ivuButton',{staticStyle:{"width":"48px"},attrs:{"disabled":item.disabled,"type":"default"}},[_vm._v(_vm._s(item.price))])],1)],1)}):_vm._e(),(_vm.type == 3 || _vm.type == 4)?_vm._l((_vm.row.content),function(item,index){return _c('el-col',{key:("btn-" + index),attrs:{"span":8}},[_c('ButtonGroup',{class:{
                        'btnNoborder': _vm.playStatus >= 100 ? true : _vm.isLottery? item.desc == _vm.row.result_str: true,
                        'isLottery': ( item.desc != _vm.row.result_str && _vm.isLottery ),
                        'noFirstRow': index > 2 
                    },attrs:{"size":"small"}},[_c('ivuButton',{staticStyle:{"width":"48px"},attrs:{"type":"error"}},[_vm._v(_vm._s(item.desc)+_vm._s(_vm.type == 3? '球': ''))]),_c('ivuButton',{staticStyle:{"width":"48px"},attrs:{"type":"default"}},[_vm._v(" "+_vm._s(_vm.playStatus >= 100 ? '1.00' : item.odds)+" ")])],1)],1)}):_vm._e(),(_vm.type == 5)?_vm._l((_vm.row.content),function(item,index){return _c('el-col',{key:("btn-" + index),attrs:{"span":8}},[_c('ButtonGroup',{class:{
                        'btnNoborder': _vm.playStatus >= 100 ? true : _vm.isLottery ? item.desc == _vm.row.result_str: true,
                        'isLottery': ( item.desc != _vm.row.result_str && _vm.isLottery ),
                        'noFirstRow': index > 2
                    },attrs:{"size":"small"}},[_c('ivuButton',{staticStyle:{"width":"48px"},attrs:{"type":"error"}},[_vm._v(_vm._s(item.desc.slice(0,1)+' / '+item.desc.slice(1)))]),_c('ivuButton',{staticStyle:{"width":"48px"},attrs:{"type":"default"}},[_vm._v(" "+_vm._s(_vm.playStatus >= 100 ? '1.00' : item.odds)+" ")])],1)],1)}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }