<template>
    <div class="tagsPage">
        <span class="circleBtn" v-if="type == 2" :class="{ 
            'noBg': concede == 0 ,
            'red':concede > 0 
            }">{{ concede != '0'? concede: '' }}</span>
        <!-- play_type:1，play_type:2单场胜平负 。play_type:3：单场总进球。play_type:4单场比分play_type:5单场全半场 -->
        <el-row :gutter="10" class="row">
            <template v-if="type == 2">
                <el-col :span="8" v-for="( item, index ) in btnList" :key="`btn-${index}`">
                    <button-group size="small"
                        :class="{
                            'btnNoborder': isLottery&&row.result_str!='延期'? ( !item.disabled && item.isPrize ): !item.disabled,
                            'isLottery': !item.disabled && isLottery
                        }">
                        <ivu-button :disabled="item.disabled" style="width: 48px;" type="error">{{ item.name }}</ivu-button>
                        <ivu-button v-if="isLottery" :disabled="item.disabled" style="width: 48px;" type="default">    
                           {{ ( !item.disabled && item.isPrize )? item.price: '&nbsp;' }}
                            </ivu-button>
                        <ivu-button v-else :disabled="item.disabled" style="width: 48px;" type="default">{{ item.price }}</ivu-button>
                    </button-group>
                </el-col>
            </template>
            <template v-if="type == 3 || type == 4">
                <el-col :span="8" v-for="( item, index ) in row.content" :key="`btn-${index}`">
                    <button-group size="small"
                        :class="{
                            'btnNoborder': playStatus >= 100 ? true : isLottery? item.desc == row.result_str: true,
                            'isLottery': ( item.desc != row.result_str && isLottery ), 'noFirstRow': index > 2
                        }">
                        <ivu-button v-if="type == 3 || type == 4 " style="width: 48px;" type="error">{{ item.desc }}{{ type == 3? '球':''}}</ivu-button>
                        <ivu-button v-if="isLottery || playStatus >= 100" style="width: 48px;" type="default">
                            {{
                                playStatus >= 100
                                    ? '1.00'
                                    : item.desc == row.result_str
                                        ? row.result
                                        : '&nbsp;'
                            }}
                        </ivu-button>
                        <ivu-button v-else style="width: 48px;" type="default">{{ item.odds }}</ivu-button>
                    </button-group>
                </el-col>
            </template>
              <template v-if="type == 6">
                <el-col :span="12" v-for="( item, index ) in row.content" :key="`btn-${index}`">
                    <button-group size="small"
                        :class="{
                            'btnNoborder': playStatus >= 100 ? true : isLottery? item.desc == row.result_str: true,
                            'isLottery': ( item.desc != row.result_str && isLottery ), 'noFirstRow': index > 1
                        }">
                        <ivu-button v-if="type == 6" style="width: 80px;" type="error">
                        <template v-if="item.desc == 'up_single'">上单</template>
                        <template v-if="item.desc == 'up_double'">上双</template>
                        <template v-if="item.desc == 'down_single'">下单</template>
                        <template v-if="item.desc == 'down_double'">下双</template>
                        </ivu-button>
                        <ivu-button v-if="isLottery || playStatus >= 100" style="width: 80px;" type="default">
                            {{
                                playStatus >= 100
                                    ? '1.00'
                                    : item.desc == row.result_str
                                        ? row.result
                                        : '&nbsp;'
                            }}
                        </ivu-button>
                        <ivu-button v-else style="width: 80px;" type="default">{{ item.odds }}</ivu-button>
                    </button-group>
                </el-col>
            </template>
            <template v-if="type == 5">
                <el-col :span="8" v-for="( item, index ) in row.content" :key="`btn-${index}`">
                    <button-group size="small"
                        :class="{
                            'btnNoborder': playStatus >= 100 ? true : isLottery? item.desc == row.result_str: true,
                            'isLottery': ( item.odds != row.result && isLottery ), 'noFirstRow': index > 2
                        }">
                        <ivu-button style="width: 48px;" type="error">{{ item.desc.slice(0,1)+' / '+item.desc.slice(1) }}</ivu-button>
                        <ivu-button v-if="isLottery || playStatus >= 100" style="width: 48px;" type="default">
                            {{
                                playStatus >= 100
                                    ? '1.00'
                                    : item.desc == row.result_str
                                        ? row.result
                                        : '&nbsp;'
                            }}
                        </ivu-button>
                        <ivu-button v-else style="width: 48px;" type="default">{{ item.odds }}</ivu-button>
                    </button-group>
                </el-col>
            </template>
        </el-row>
    </div>
</template>

<script>
    import { ButtonGroup, Button as ivuButton } from 'iview';
    export default {
        props: [ 'row', 'concede', 'isLottery', 'type', 'playStatus' ],
        data () {
            return {
                btnList: [],
            };
        },
        created () {
            this.row.content = JSON.parse( this.row.content );
            switch ( this.type ) {
                case 1:
                    this.isActiveBtn();
                    break;
                case 2:
                    this.isActiveBtn();
                    break;
                default:
                    break;
            }
        },
        methods: {
            // eslint-disable-next-line complexity
            isActiveBtn () {
                this.btnList = [
                    { name: '胜', price: this.row.src_odds_data.win, disabled: true, isPrize: false },
                    { name: '平', price: this.row.src_odds_data.draw, disabled: true, isPrize: false },
                    { name: '负', price: this.row.src_odds_data.lose, disabled: true, isPrize: false },
                ];
                let activeArr = [];
                this.row.content.map( ( item ) => activeArr.push( item.desc ) );
                if ( activeArr.includes( 'win' ) ) {
                    this.btnList[ 0 ].disabled = false;
                }
                if ( activeArr.includes( 'draw' ) ) {
                    this.btnList[ 1 ].disabled = false;
                }
                if ( activeArr.includes( 'lose' ) ) {
                    this.btnList[ 2 ].disabled = false;
                }

                if ( this.isLottery ) {
                    if ( this.row.result_str == 'win' ) {
                        this.btnList[ 0 ].isPrize = true;
                        if ( this.row.result_sp === 0 ) {
                            this.btnList[ 0 ].price = this.row.src_odds_data.win;
                        } else {
                            this.btnList[ 0 ].price = this.row.result.win;
                        }
                    }
                    if ( this.row.result_str == 'draw' ) {
                        this.btnList[ 1 ].isPrize = true;
                        if ( this.row.result_sp === 0 ) {
                            this.btnList[ 1 ].price = this.row.src_odds_data.draw;
                        } else {
                            this.btnList[ 1 ].price = this.row.result.draw;
                        }
                    }
                    if ( this.row.result_str == 'lose' ) {
                        this.btnList[ 2 ].isPrize = true;
                        if ( this.row.result_sp === 0 ) {
                            this.btnList[ 2 ].price = this.row.src_odds_data.lose;
                        } else {
                            this.btnList[ 2 ].price = this.row.result.lose;
                        }
                    }
                } else if ( this.playStatus >= 100 ) {// 延期判断
                    this.btnList.map( ( item ) => {
                        if ( !item.disabled ) {
                            item.isPrize = true;
                            item.price = '1.00';
                        }
                    } );
                }
            },
        },
        components: {
            ButtonGroup,
            ivuButton,
        },
    };
</script>

<style lang="less" scoped>
    .tagsPage {
        padding: 10px 0;
        display: flex;
        .row {
            flex: 1;
        }
        .circleBtn{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 22px;
            height: 26px;
            background: #eee;
            // border-radius: 50%;
            color: #00a79d;
            margin-right: 10px;
            font-weight: 600;
        }
        .red {
            color: (#BE0B00);
        }
        .noBg {
            background: #fff;
        }
        .noFirstRow {
            margin-top: 8px;
        }
        ::v-deep .el-col-8{
            width: 32%;
            padding: 0 !important;
        }
    }
</style>
